export const httpClient = () => {
  const { token } = JSON.parse(localStorage.getItem('auth')) || {};
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': '0fe30ab5e9084a06aae9acf3d2684e4f',
    'Ocp-Apim-Trace': true
  };
};


export const authProvider = {
  // authentication

  login: ({ username, password }) => {
    const request = new Request(
      process.env.REACT_APP_API_URL + '/users/login',
      {
        method: 'POST',
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': '0fe30ab5e9084a06aae9acf3d2684e4f',
          'Ocp-Apim-Trace': true
        }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem(
          'auth',
          JSON.stringify({ ...auth, fullName: username })
        );
        localStorage.setItem('roles', auth.role);
        localStorage.setItem('permissions', auth.role);

      })
      .catch(() => {
        throw new Error('Network error (1)');
      });
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },

  checkAuth: () => {
    const path = window.location.href.split('#');
    const currentresource = path[1].split('?')[0];
    var checkSuccess = false;
    const role = localStorage.getItem('roles');
    const loginErrorMsg = {
      message: 'login required'
    }
    const accessDeniedMsg = {
      message: 'Access denied',
      redirectTo: '/',
      logoutUser: false
    }

    if (localStorage.getItem('auth')) {
      checkSuccess = true;
      if (role === 'orgadmin') {
        currentresource.includes('customers') || currentresource.includes('settings') ? checkSuccess = false : checkSuccess = true;
      } else if (role === 'doctor') {
        currentresource.includes('customers') || currentresource.includes('orgadmins') || currentresource.includes('settings') ? checkSuccess = false : checkSuccess = true;
      }
    } else {
      checkSuccess = false;
    }
    return checkSuccess
      ? Promise.resolve()
      : Promise.reject(localStorage.getItem('auth') ? accessDeniedMsg : loginErrorMsg);
  },

  logout: () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('permissions');
    localStorage.removeItem('roles');
    localStorage.removeItem('email');
    return Promise.resolve('/login');
  },

  getIdentity: () => {
    try {
      const { id, fullName, avatar } = JSON.parse(localStorage.getItem('auth'));
      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return Promise.resolve(role);
  },

  getRole: () => {
    return localStorage.getItem('roles');
  }
};