import React, { useState, useEffect } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  RadioButtonGroupInput,
  PasswordInput,
  useNotify,
  useRefresh,
  useRedirect,
  useQuery,
  required,
  maxLength,
  email,
  minLength,
  regex,
  BooleanInput,
} from 'react-admin';
import Constants from '../Constants';

const DoctorCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify('simple.notify.save_doctor');
    redirect(`/doctors?filter=%7B"id"%3A"${data.id}"%7D`);
    refresh();
  };

  const createdDateTime = new Date().toLocaleString();

  const DefValues = () => ({
    state: 'active', updated: createdDateTime,
    customerid: localStorage.getItem('roles') === 'orgadmin' ? JSON.parse(localStorage.getItem('auth')).customerid : null,
  });

  const [customers, setCustomers] = useState([]);
  const { data: customer } = useQuery({
    type: 'get',
    resource: 'customers',
    payload: {
      sort: { field: 'name', order: 'ASC' },
      filter: JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' ?
        {
          state: Constants.ACTIVE,
          id: JSON.parse(localStorage.getItem('auth'))?.customerid
        } : {
          state: Constants.ACTIVE,
        },
    },
  });

  useEffect(() => {
    if (customer) {
      setCustomers(customer.map((d) => ({ id: d.id, name: d.name })));
    }
  }, [customer]);

  const validateName = [required(), maxLength(50, 'simple.validation.maxLength50')];
  const validateEmail = [required(), email('simple.validation.validEmail'), maxLength(100, 'simple.validation.maxLength100')];
  const validatePassword = [required(), maxLength(12, 'simple.validation.maxLength12'),
  minLength(8, 'simple.validation.greaterorEqual8'),
  //eslint-disable-next-line
  regex(/^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,12}$/,
    'simple.validation.validPassword')];
  const validatePhoneNumber = [required(), maxLength(20, 'simple.validation.maxLength20'),
  regex(/^[0-9]+$/, 'simple.validation.onlyNumbers')];
  const validateCustomer = [required()];
  const validateRegNumber = [maxLength(15, 'simple.validation.maxLength15')];

  return (
    <Create {...props} title='simple.createTitles.doctor' onSuccess={onSuccess}>
      <SimpleForm initialValues={DefValues}>
        <TextInput source='name' validate={validateName} />
        <TextInput source='email' validate={validateEmail} />
        {/* <PasswordInput source='password' validate={validatePassword} /> */}
        <TextInput source='phonenumber' validate={validatePhoneNumber} />
        <TextInput source='regnumber' validate={validateRegNumber} />
        <SelectInput source='customerid' choices={customers} validate={validateCustomer} />
        <BooleanInput source="isorgadmin" />
        <RadioButtonGroupInput source='state' choices={[{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }]} />
        <TextInput disabled source='updated' />
      </SimpleForm>
    </Create>
  );
}

export default DoctorCreate;