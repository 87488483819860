import React from 'react';
import { List, useTranslate, Filter, Datagrid, TextField, EmailField, SearchInput, TopToolbar, DeleteButton, BulkDeleteButton, ExportButton, CreateButton } from 'react-admin';

// ------------- filter component -------------

const DoctorFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchInput placeholder={translate('simple.search.doctor')} source='name' resettable alwaysOn />
    </Filter>
  );
}

const NoneActions = props => {
  if (localStorage.getItem('roles') === 'doctor') {
    return <TopToolbar />
  } else {
    return <TopToolbar>
      <CreateButton />
      <ExportButton />
      <DeleteButton
        undoable={false}   {...props}
        confirmTitle='simple.notify.confirmTitle'
        confirmContent='simple.notify.confirmContent'
      />
    </TopToolbar>

  }
}

const DoctorList = (props) => {
  const hideCheckBoxes = props => {
    if (localStorage.getItem('roles') === 'doctor') {
      return false
    } else {
      return <BulkDeleteButton
        undoable={false}
        confirmTitle='simple.notify.confirmTitle'
        confirmContent='simple.notify.confirmContent'
        {...props}
      />
    }
  }

  return (
    <List title='simple.listTitles.doctor' actions={<NoneActions />} bulkActionButtons={hideCheckBoxes()}
      {...props} filters={<DoctorFilter />} sort={{ field: 'updated', order: 'DESC' }}

      filter={
        JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' ? {
          customerid: JSON.parse(localStorage.getItem('auth'))?.customerid
        } : JSON.parse(localStorage.getItem('auth')).role === 'doctor' ? {
          id: JSON.parse(localStorage.getItem('auth'))?.doctorid
        } : {}
      }
    >
      <Datagrid rowClick='edit' isRowSelectable={record => record.id !== JSON.parse(localStorage.getItem('auth')).doctorid}>
        <TextField source='name' />
        <EmailField source='email' />
        <TextField source='phonenumber' />
        <TextField source='regnumber' />
        <TextField source='customername' />
        <TextField source='state' />
        <TextField source='isorgadmin' />
        <TextField source='updated' />
      </Datagrid>
    </List>
  )
};

export default DoctorList;