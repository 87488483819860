import React, { useState } from 'react';
import {
  List,
  Filter, Datagrid, TextField, useTranslate, BulkDeleteButton,
  EmailField, SearchInput, FunctionField,TopToolbar,useNotify
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { DASHBOARDLINK,CONFIG, INACTIVE, ACTIVE } from './../Constants';
import Popup from "reactjs-popup";
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
//state for Delete Button

// ------------- filter component -------------
const TransactionHistoryList = (props) => {
  const notify = useNotify();
  const translate = useTranslate();
  const SubscriptionPlanFilter = (props) => {
    return (
      <Filter {...props}>
        <SearchInput placeholder={translate('simple.search.email')} source='email' resettable alwaysOn />
      </Filter>
    );
  }
  const sendToAllNotification = async e => {
    // e.preventDefault();
    // eslint-disable-next-line no-undef
    var body = {
      "id": props.selectedIds,
      "message": message,
      "liteAccount":true
    }
    await axios.post(process.env.REACT_APP_API_URL + `/sendtoallpushnotification`, body, CONFIG).then((res) => {
      if (res.status == 204) {
        toast.success(translate('simple.validation.notificationSuccessful'));
        notify('simple.notify.save_settings');
      }
      else{
        toast.error('something went wrong')
      }
    }).catch(err =>{
      toast.error(err.message)
    })
  }

  const [message, setuser] = useState();

  const onInputChange = e => {
    setuser(e.target.value)
  }

  const Actions = props => {
    const [message, setuser] = useState();

    const onInputChange = e => {
      setuser(e.target.value)
    }

    var body = {
      "id": props.selectedIds,
      "message": message
    }

    const onSubmit = async e => {
      // e.preventDefault();
      // eslint-disable-next-line no-undef
      await axios.post(process.env.REACT_APP_API_URL + `/pushnotification`, body, CONFIG).then((res) => {
        if (res.status == 204) {
          toast.success(translate('simple.validation.notificationSuccessful'));
          notify('simple.notify.save_settings');
        }
        else{
          toast.error('something went wrong')
        }
      }).catch(err =>{
        toast.error(err.message)
      })
      
    }



    return <TopToolbar>
      <BulkDeleteButton
        undoable={false}
        confirmTitle='simple.notify.confirmTitle'
        confirmContent='simple.notify.confirmContent'
        {...props}
      />
      <Popup trigger={<Button color="primary" className="MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-textPrimary"><NotificationsIcon />
        {translate('resources.patients.popup.sendnotification')}</Button>} modal nested >
        {close => (
          <div className="modal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="header">{translate('resources.patients.popup.enternotificationmsg')}</div>
            <div className="content">
              <textarea onChange={e => onInputChange(e)} />
            </div>
            <div className="actions">
              <button className="button" onClick={() => { onSubmit(); close(); }}>{translate('resources.patients.popup.send')}</button>
              <button
                className="button closeButton"
                onClick={() => {
                  close();
                }}>{translate('resources.patients.popup.close')}</button>
            </div>
          </div>
        )}
      </Popup>
    </TopToolbar>
  }

  const handleSendButtonClick = () => {
    if (window.confirm(`Are you sure? You are sending a notification to all lite users.`)) {
      sendToAllNotification();
    }
  };
  const calculateDaysLeft = (e) => {
    let subscriptionEndDate =null
    if (typeof e.subscriptionEndDate === 'string') {
      console.log('cds in if')
      subscriptionEndDate =  ConvertstringDateIntoDateObject(e.subscriptionEndDate)
    } else {
      console.log('dvcd')
      subscriptionEndDate = new Date(e.subscriptionEndDate);
    }

    const currentDate = new Date();
    const timeDifference = subscriptionEndDate.getTime() - currentDate.getTime();
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert milliseconds to days
    return daysLeft;
  };

  const ConvertstringDateIntoDateObject = (e) => {
    // Split the date string into date and time parts
    var parts = e.split(/[\s,]+/);
    
    var dateParts = parts[0].split('/');
    var timeParts = parts[1].split(':');
    var ampm = parts[2];

    // Extract date components
    var day = parseInt(dateParts[1], 10);
    var month = parseInt(dateParts[0], 10) - 1; // Months are 0-based in JavaScript
    var year = parseInt(dateParts[2], 10);

    // Extract time components
    var hour = parseInt(timeParts[0], 10);
    var minute = parseInt(timeParts[1], 10);
    var second = parseInt(timeParts[2], 10);
    
    // Adjust hour for PM times
    if (ampm === "PM" && hour < 12) {
        hour += 12;
    }

    // Validate month value
    if (month < 0 || month > 11) {
        console.error('Invalid month value:', month);
        // return null; // Return null or handle the error appropriately
    }

    // Create a new Date object with date and time components
    const newDate = new Date(year, month, day, hour, minute, second);

    console.log("🚀 ~ ConvertstringDateIntoDateObject ~ newDate:", newDate);

    return newDate;
}

  const checkSubscriptionStatus = (e)=>{
    const remainingDays = calculateDaysLeft(e)
    if(remainingDays < 0){
      return INACTIVE
    }
    else return ACTIVE
  }
  return (

     <>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', marginRight: '10px' }}>
          <Popup trigger={<Button color="primary" className="MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-textPrimary"><NotificationsIcon/>{translate('resources.patients.popup.sendToAll')}</Button>} modal nested>
            {close => (
              <div className="modal">
                {/* Customize the content for your new button's popup */}
                <button className="close" onClick={close}>
                  &times;
                </button>
                <div className="header">{translate('resources.patients.popup.enternotificationmsgtoalluser')}</div>
                <div className="content">
                  <textarea onChange={e => onInputChange(e)} />
                </div>
                <div className="actions">
                  <button className="button" onClick={() => { handleSendButtonClick(); close(); }}>
                    {translate('resources.patients.popup.send')}
                  </button>
                  <button className="button closeButton" onClick={close}>
                    {translate('resources.patients.popup.close')}
                  </button>
                </div>
              </div>
            )}
          </Popup>
        </div>
        <div>
          <ToastContainer style={{ marginTop: '30px' }} />
        </div>
    <List title='simple.listTitles.liteAccounts' sort={{ field: 'patient.updated', order: 'DESC' }} 
      filters={<SubscriptionPlanFilter />} bulkActionButtons={<Actions />} {...props} >
      <Datagrid rowClick='edit' >
        <EmailField source='patient.email' label={translate('resources.transactionHistory.email')} />
        <TextField source='patient.pseudonym' label={translate('resources.transactionHistory.pseudonym')} />
        <TextField source='createdAt' label={translate('resources.transactionHistory.createdAt')}/>
        <TextField source='subscriptionType' label={translate('resources.transactionHistory.subscriptionType')} />
        {/* <TextField source='daysLeft' label={translate('resources.transactionHistory.daysLeft')}/> */}
        <FunctionField source='daysLeft' render={record => calculateDaysLeft(record)} label={translate('resources.transactionHistory.daysLeft')}/>
        <TextField source='subscriptionDate' label={translate('resources.transactionHistory.subscriptionStartDate')} />
        <TextField source='subscriptionEndDate' label={translate('resources.transactionHistory.subscriptionEndDate')} />
        {/* <TextField source='subscriptionstatus' label={translate('resources.transactionHistory.subscriptionstatus')} /> */}
        <FunctionField source='subscriptionstatus' render={record => checkSubscriptionStatus(record)} label={translate('resources.transactionHistory.subscriptionstatus')}/>
        <TextField source='state' label={translate('resources.transactionHistory.state')} />
        {/* <TextField source='accountCreated' label="Account created" /> */}
        <TextField source='patient.updated' label={translate('resources.transactionHistory.updated')} />
        <FunctionField label={translate('resources.transactionHistory.dashboard')}  render={record =>
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={DASHBOARDLINK + record.id} target="_blank" >Dashboard</a>} />
      </Datagrid>
    </List>
    </>
  )
};

export default TransactionHistoryList


