import React from 'react';
import { List, useTranslate, Filter, Datagrid, TextField, SearchInput, TopToolbar, DeleteButton, BulkDeleteButton, CreateButton, ExportButton } from 'react-admin';

// ------------- filter component -------------
const FQAFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchInput placeholder={translate('simple.search.FAQ')} source='question' resettable alwaysOn />
    </Filter>
  );
}

const MyBulkActionButtons = props => {
  if (JSON.parse(localStorage.getItem('auth')).role === 'doctor') {
    return false
  } else {
    return <BulkDeleteButton
      undoable={false}
      confirmTitle='simple.notify.confirmTitleFAQ'
      confirmContent='simple.notify.confirmContent'
      {...props}
    />
  }
}

const MyActions = props => {
  if (JSON.parse(localStorage.getItem('auth')).role === 'doctor') {
    return <TopToolbar>
      <ExportButton />
    </TopToolbar>
  } else {
    return <TopToolbar>
      <CreateButton />
      <ExportButton />
      <DeleteButton
        undoable={false}
        confirmTitle='simple.notify.confirmTitleFAQ'
        confirmContent='simple.notify.confirmContent'
      />
    </TopToolbar>
  }

}

const postRowClick = (id, resource, record) => {
  return (record.customerid === "" && (JSON.parse(localStorage.getItem('auth')).role === 'orgadmin'
    || JSON.parse(localStorage.getItem('auth')).role === 'doctor'))
    ? undefined : 'edit';
}

const FQAList = (props) => {
  return (
    <List {...props} title='simple.listTitles.FAQ' actions={<MyActions />} bulkActionButtons={MyBulkActionButtons(props)}
      filters={<FQAFilter />}
      filter={
        {
          customerid: JSON.parse(localStorage.getItem('auth'))?.customerid ? JSON.parse(localStorage.getItem('auth'))?.customerid : ""
        }
      }
      sort={{ field: 'updated', order: 'DESC' }}>
      <Datagrid rowClick={postRowClick} isRowSelectable={record => record.customerid === "" &&
        (JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' || JSON.parse(localStorage.getItem('auth')).role === 'doctor') ? false : true} >
        <TextField source='question' />
        <TextField source='answer' />
        <TextField source='state' />
        <TextField source='updated' />
        <TextField source='languagecode'/>
      </Datagrid>
    </List>

  )
}


export default FQAList;