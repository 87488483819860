import React, { useState } from 'react';
import {
  List, Filter, Datagrid, TextField, TopToolbar, NumberField, useNotify,
  EmailField, SearchInput, FunctionField, BulkDeleteButton, useTranslate,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import redDown from "./images/red-down-arrow.png";
import greenUp from "./images/Green-Arrow-UP.png";
import grayQuestionMark from "./images/grey-question-mark.jpg";
import orangeRight from "./images/orange-right-arrow.png";
import orangeRound from "./images/orange-round.png";
import redRound from "./images/red-round.png";
import greenRound from "./images/green-round.png";
import { DASHBOARDLINK, CONFIG } from './../Constants';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Popup from "reactjs-popup";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
const PatientList = (props) => {
  const notify = useNotify();

  const useStyles = makeStyles({
    root: {
      maxWidth: "100%",
      position: "top",
      height: "100%",
    },
    media: {
      height: "14px",
      width: "14px",
      objectFit: "contain"
    }
  });

  const classes = useStyles();
  const translate = useTranslate();

  const PatientListFilters = (props) => {
    return (
      <Filter {...props}>
        <SearchInput placeholder={translate('simple.search.patient')} source='doctor' resettable alwaysOn />
      </Filter>
    );
  }
  const [message, setuser] = useState();
  const [loading, setLoading] = useState(false);
    const onInputChange = e => {
      setuser(e.target.value)
    }
  const sendToAllNotification = async e => {
    try {
      setLoading(true);

      const body = {
        "id": props.selectedIds,
        "message": message,
      };

      const response = await axios.post(process.env.REACT_APP_API_URL + '/sendtoallpushnotification', body, CONFIG);

      if (response.status === 204) {
        console.log("fds");
        toast.success(translate('simple.validation.notificationSuccessful')); 
        notify('simple.notify.save_settings');
      } else {
        toast.error('Something went wrong');
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }

  const Actions = props => {
    const [message, setuser] = useState();

    const onInputChange = e => {
      setuser(e.target.value)
    }

    var body = {
      "id": props.selectedIds,
      "message": message
    }

    const onSubmit = async e => {
      // e.preventDefault();
      // eslint-disable-next-line no-undef
      await axios.post(process.env.REACT_APP_API_URL + `/pushnotification`, body, CONFIG).then((res) => {
        if (res.status == 204) {
          toast.success(translate('simple.validation.notificationSuccessful'));
          notify('simple.notify.save_settings');
        }
        else{
          toast.error('something went wrong')
        }
      }).catch(err =>{
        toast.error(err.message)
      })
    }


    return <TopToolbar>
      <BulkDeleteButton
        undoable={false}
        confirmTitle='simple.notify.confirmTitle'
        confirmContent='simple.notify.confirmContent'
        {...props}
      />
      <Popup trigger={<Button color="primary" className="MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-textPrimary"><NotificationsIcon />
        {translate('resources.patients.popup.sendnotification')}</Button>} modal nested >
        {close => (
          <div className="modal">
            <button className="close" onClick={close}>
              &times;
            </button>
            <div className="header">{translate('resources.patients.popup.enternotificationmsg')}</div>
            <div className="content">
              <textarea onChange={e => onInputChange(e)} />
            </div>
            <div className="actions">
              <button className="button" onClick={() => { onSubmit(); close(); }}>{translate('resources.patients.popup.send')}</button>
              <button
                className="button closeButton"
                onClick={() => {
                  close();
                }}>{translate('resources.patients.popup.close')}</button>
            </div>
          </div>
        )}
      </Popup>
    </TopToolbar>
  }

  const PreviewTrend = ({ record }) => {
    if (record.patient.trend === "Red") {
      return <CardMedia
        component="img"
        className={classes.media}
        src={redDown}
        title={translate('simple.listTitles.tooltips.trendRedArrow')}
      />
    } else if (record.patient.trend === "Green") {
      return <CardMedia
        component="img"
        className={classes.media}
        src={greenUp}
        title={translate('simple.listTitles.tooltips.trendGreenArrow')}
      />
    } else if (record.patient.trend === "Yellow") {
      return <CardMedia
        component="img"
        className={classes.media}
        src={orangeRight}
        title={translate('simple.listTitles.tooltips.trendyellowArrow')}
      />
    } else {
      return <CardMedia
        component="img"
        className={classes.media}
        src={grayQuestionMark}
        title={translate('simple.listTitles.tooltips.noTrendActivity')}
      />
    }

  }

  const PreviewActivityLevel = ({ record }) => {
    if (record.patient.activitylevel === "Red") {
      return <CardMedia
        component="img"
        className={classes.media}
        src={redRound}
        title={translate('simple.listTitles.tooltips.ACLevelRedRound')}
      />
    }
    else if (record.patient.activitylevel === "Green") {
      return <CardMedia
        component="img"
        className={classes.media}
        src={greenRound}
        title={translate('simple.listTitles.tooltips.ACLevelGreenRound')}
      />
    } else if (record.patient.activitylevel === "Yellow") {
      return <CardMedia
        component="img"
        className={classes.media}
        src={orangeRound}
        title={translate('simple.listTitles.tooltips.ACLevelYellowRound')}
      />
    } else {
      return <CardMedia
        component="img"
        className={classes.media}
        src={grayQuestionMark}
        title={translate('simple.listTitles.tooltips.ACLevelNoActivity')}
      />
    }

  }

  const handleSendButtonClick = () => {
    if (window.confirm(`Are you sure? You are sending a notification to all the users.`)) {
      sendToAllNotification();
    }
  };

  return (
    <>

      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', marginRight: '10px' }}>
        <Popup trigger={<Button color="primary" className="MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-textPrimary"><NotificationsIcon/>{translate('resources.patients.popup.sendToAll')}</Button>} modal nested>
          {close => (
            <div className="modal">
              {/* Customize the content for your new button's popup */}
              <button className="close" onClick={close}>
                &times;
              </button>
              <div className="header">{translate('resources.patients.popup.enternotificationmsgtoalluser')}</div>
              <div className="content">
                <textarea onChange={e => onInputChange(e)} />
              </div>
              <div className="actions">
                <button className="button" onClick={() => { handleSendButtonClick(); close(); }} disabled={loading}>
                {loading ? 'Sending...' : translate('resources.patients.popup.send')}
                </button>
                <button className="button closeButton" onClick={close}>
                  {translate('resources.patients.popup.close')}
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>
      <div>
        <ToastContainer style={{ marginTop: '30px' }} />
      </div>
      <List {...props} title='simple.listTitles.patient' bulkActionButtons={<Actions />} filters={<PatientListFilters />} sort={{ field: 'patient.updated', order: 'DESC' }}
        filter={JSON.parse(localStorage.getItem('auth')).role ? ((JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' ||
          JSON.parse(localStorage.getItem('auth')).removerestriction === true) ?
          {
            customerid: JSON.parse(localStorage.getItem('auth'))?.customerid
          } :
          {
            doctorid: JSON.parse(localStorage.getItem('auth'))?.doctorid ? JSON.parse(localStorage.getItem('auth'))?.doctorid : {}
          }) : {}}

      >
        <Datagrid rowClick='edit' >
          {JSON.parse(localStorage.getItem('auth')).patientcolumns.includes('pseudonym') || JSON.parse(localStorage.getItem('auth')).patientcolumns.length === 0
            ? <TextField source='patient.pseudonym' /> : null}
          {JSON.parse(localStorage.getItem('auth')).patientcolumns.includes('name') || JSON.parse(localStorage.getItem('auth')).patientcolumns.length === 0
            ? <TextField source='patient.name' /> : null}
          {JSON.parse(localStorage.getItem('auth')).patientcolumns.includes('name') || JSON.parse(localStorage.getItem('auth')).patientcolumns.length === 0
            ? <NumberField source='patient.daysfromoperation' /> : null}
          {JSON.parse(localStorage.getItem('auth')).patientcolumns.includes('activitylevel') || JSON.parse(localStorage.getItem('auth')).patientcolumns.length === 0
            ? <PreviewActivityLevel source="patient.activitylevel" /> : null}
          {JSON.parse(localStorage.getItem('auth')).patientcolumns.includes('trend') || JSON.parse(localStorage.getItem('auth')).patientcolumns.length === 0
            ? <PreviewTrend source="patient.trend" /> : null}
          {JSON.parse(localStorage.getItem('auth')).patientcolumns.includes('phonenumber') || JSON.parse(localStorage.getItem('auth')).patientcolumns.length === 0
            ? <TextField source='patient.phonenumber' /> : null}
          {JSON.parse(localStorage.getItem('auth')).patientcolumns.includes('email') || JSON.parse(localStorage.getItem('auth')).patientcolumns.length === 0
            ? <EmailField source='patient.email' /> : null}
          {JSON.parse(localStorage.getItem('auth')).patientcolumns.includes('doctor') || JSON.parse(localStorage.getItem('auth')).patientcolumns.length === 0
            ? <TextField source='doctor' /> : null}
          {JSON.parse(localStorage.getItem('auth')).patientcolumns.includes('dashboard') || JSON.parse(localStorage.getItem('auth')).patientcolumns.length === 0
            ? <FunctionField label="Dashboard" render={record =>
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={DASHBOARDLINK + record.id} target="_blank" >Dashboard</a>} /> : null}
          {JSON.parse(localStorage.getItem('auth')).patientcolumns.includes('state') || JSON.parse(localStorage.getItem('auth')).patientcolumns.length === 0
            ? <TextField source="patient.state" /> : null}
          {JSON.parse(localStorage.getItem('auth')).patientcolumns.includes('updated') || JSON.parse(localStorage.getItem('auth')).patientcolumns.length === 0
            ? <TextField source='patient.updated' /> : null}
        </Datagrid>
      </List>
      <div className="notificationPopupOverlay">
      </div>
    </>
  )
};

export default PatientList;