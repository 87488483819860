import React,{useState} from 'react';
import {
  Edit, regex,useEditContext,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  SelectInput,
  required,
  SelectArrayInput,
  ImageInput,
  ImageField,
  maxLength,
  Labeled,
  DateTimeInput, Toolbar, SaveButton, DeleteButton, useTranslate, CheckboxGroupInput,BooleanInput,SimpleFormIterator,ArrayInput,Button
} from 'react-admin';

const CustomerEdit = (props) => {
  const defaultChatLink = '';
  const [linkContentFields, setLinkContentFields] = useState([{ link: '', content: '' }]);
  const [chatContentFields, setChatContentFields] = useState(false);
  const translate = useTranslate();
  const PreviewImage = ({ record, source }) => {
    
    if (typeof (record) == "string") {
      record = {
        [source]: record
      }
    }
    return <ImageField record={record} source={source} />
  }

  const EditToolbar = props => {
    return <div className="deleteButton">
      <Toolbar {...props} >
        <SaveButton disabled={props.pristine} />
        <DeleteButton
          undoable={false}
          confirmTitle='simple.notify.confirmTitle'
          confirmContent='simple.notify.confirmContent'
        />
      </Toolbar>
    </div>
  };


  const validateName = [required(), maxLength(50, 'simple.validation.maxLength50')];
  const validateCustomerCode = [required(), maxLength(50, 'simple.validation.maxLength50')];
  const validateAddress = [maxLength(100, "simple.validation.maxLength100")];
  const validateCity = [maxLength(50, 'simple.validation.maxLength50')];
  const validatePostalCode = [maxLength(10, 'simple.validation.maxLength10'), regex(/^([-A-Za-z0-9]){1,20}$/, 'simple.validation.alphaOrHyphen')];
  const validateCountry = [maxLength(50, 'simple.validation.maxLength50')];
  const validateVersion = [maxLength(50, 'simple.validation.maxLength50')];
  const validateColor = [maxLength(50, 'simple.validation.maxLength50')];
  const validateProduct = [required()];
  const validateLogo = [required()];
  const validatePhoneNumber = [
    maxLength(20, 'simple.validation.maxLength20'),
    regex(/^\+?\d+$/, 'simple.validation.onlyNumbersWithOptionalPlus')
  ];
  const transform = data => ({
    ...data,
    updated: new Date().toLocaleString(),
  }
  )
  console.log(transform())

  const obj = {}; // Create an object

  const choices = [
    {id:1,name:'01'},
    { id: 25, name: '25' },
    { id: 50, name: '50' },
    { id: 75, name: '75' },
    { id:90,  name: '90' }
];
const handleAddField = () => {
  setLinkContentFields([...linkContentFields, { link: '', content: '' }]);
  console.log('se',linkContentFields)
};
const chatLink = (value) => {
  console.log(value)

  setChatContentFields(value)
};
console.log(props)
  return (
    <Edit {...props} title='simple.editTitles.customer' undoable={false} transform={transform}>
      
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source='name' validate={validateName} />
        <TextInput source='customercode' validate={validateCustomerCode} />
        <TextInput source='address' validate={validateAddress} />
        <TextInput source='city' validate={validateCity} />
        <TextInput source='postalcode' validate={validatePostalCode} />
        <TextInput source='country' validate={validateCountry} />
        <TextInput source='phonenumber' validate={validatePhoneNumber}/>
        <TextInput source='smsphonenumber' validate={validatePhoneNumber}/>
        <SelectInput source='product'
          choices={[{ id: 'kuntoresearch', name: 'Kunto Research' }, { id: 'kuntorecovery', name: 'Kunto Recovery' }]}
          validate={validateProduct} />
        <TextInput source='version' validate={validateVersion} />
        <SelectArrayInput source='options' initialValue={['']}
          choices={[{ id: '', name: 'No options' }, { id: 'knee', name: 'Knee' }, { id: 'hip', name: 'Hip' }, { id: 'spine', name: 'Spine' },
          { id: 'shoulder', name: 'Shoulder' }, { id: 'wrist', name: 'Wrist' }]} />
        <ImageInput source="logo"  maxSize={1048576} accept="image/*" placeholder={translate('resources.customers.placeholder.logo')}>
          <PreviewImage source="src" />
        </ImageInput>
        <Labeled label="resources.customers.placeholder.allowedFileTypes" />
        <TextInput source='color' validate={validateColor} />
        <BooleanInput source="contact" />
        <BooleanInput source="sharing" />
        <ArrayInput source="linkContent" label="Links and Content">
          <SimpleFormIterator>
            <TextInput label="Link" source="link" />
            <TextInput label="Content" multiline source="content" />
          </SimpleFormIterator>
        </ArrayInput>
        <Button  onClick={handleAddField} />
        <BooleanInput label="Chat Option" source="chatoption" onChange={chatLink}/>
        <TextInput
            label="Chat Link"
            source="chatlink"
            defaultValue={defaultChatLink}
          />
        {/* {
          chatContentFields && (

          )
        } */}
        <SelectInput source="retentionperiod" choices={choices} label="Select Retention Period"/>
        <CheckboxGroupInput source='sendnotification' choices={[{ id: 'true', name: translate('resources.customers.fields.sendnotification') }]} />
        <RadioButtonGroupInput source='state' choices={[{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }]} />
        <DateTimeInput disabled source='updated' initialValue={new Date().toLocaleString()} />
      </SimpleForm>
    </Edit>);
};

export default CustomerEdit;