import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  RadioButtonGroupInput,
  PasswordInput,
  useQuery,
  required,
  maxLength,
  email,
  minLength,
  regex, BooleanInput, Toolbar, SaveButton, DeleteButton
} from 'react-admin';
import Constants from '../Constants';

const DoctorEdit = (props) => {
  const { id } = useParams()
  const [customers, setCustomers] = useState([]);
  const { data: doctor } = useQuery({
    type: 'getList',
    resource: 'customers',
    payload: {
      sort: { field: 'customercode', order: 'ASC' },
      filter: JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' ?
        {
          state: Constants.ACTIVE,
          id: JSON.parse(localStorage.getItem('auth'))?.customerid
        } : {
          state: Constants.ACTIVE,
        },
    },
  });

  useEffect(() => {
    if (doctor) {
      setCustomers(doctor.map((d) => ({ id: d.id, name: d.name })));
    }
  }, [doctor]);

  const validateName = [required(), maxLength(50, 'simple.validation.maxLength50')];
  const validateEmail = [required(), email('simple.validation.validEmail'), maxLength(100, 'simple.validation.maxLength100')];
  const validatePassword = [required(), maxLength(12, 'simple.validation.maxLength12'),
  minLength(8, 'simple.validation.greaterorEqual8'),
  //eslint-disable-next-line
  regex(/^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,12}$/,
    'simple.validation.validPassword')];
  const validatePhoneNumber = [required(), maxLength(20, 'simple.validation.maxLength20'),
  regex(/^[0-9]+$/, 'simple.validation.onlyNumbers')];
  const validateCustomer = [required()];
  const validateRegNumber = [maxLength(15, 'simple.validation.maxLength15')];

  const isDisable = () => {
    if (localStorage.getItem('roles') === 'doctor' || JSON.parse(localStorage.getItem('auth')).doctorid === id) {
      return true
    }
    else {
      return false
    }
  }

  const EditToolbar = props => {
    if (localStorage.getItem('roles') === 'doctor' || JSON.parse(localStorage.getItem('auth')).doctorid === id) {
      return false
    }
    else {
      return <div className="deleteButton">
        <Toolbar {...props} >
          <SaveButton disabled={props.pristine} />
          <DeleteButton
            undoable={false}
            confirmTitle='simple.notify.confirmTitle'
            confirmContent='simple.notify.confirmContent'
          />
        </Toolbar>
      </div>
    }
  };

  const transform = data => ({
    ...data,
    updated: new Date().toLocaleString(),
  })

  return (
    <Edit {...props} title='simple.editTitles.doctor' undoable={false} transform={transform}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput disabled={isDisable()} source="name" validate={validateName} />
        <TextInput disabled={isDisable()} source="email" validate={validateEmail} />
        <TextInput disabled={isDisable()} source="phonenumber" validate={validatePhoneNumber} />
        <TextInput disabled={isDisable()} source="regnumber" validate={validateRegNumber} />
        <SelectInput disabled={isDisable()} source='customerid' choices={customers} validate={validateCustomer} />
        <BooleanInput disabled={isDisable()} source="isorgadmin" />
        <RadioButtonGroupInput disabled={isDisable()} source='state' choices={[{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }]} />
        <TextInput disabled source='updated' initialValue={new Date().toLocaleString()} />
      </SimpleForm>
    </Edit>
  );
}

export default DoctorEdit;