import React from 'react';
import {
  List,
  Filter, Datagrid, TextField, CreateButton, useTranslate,
  ExportButton, EmailField, SearchInput, TopToolbar, DeleteButton, BulkDeleteButton,FunctionField
} from 'react-admin';
import { CONFIG } from './../Constants';
import { authProvider } from '../authProvider'
import axios from 'axios';
// ------------- filter component -------------
const OrgAdminFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchInput placeholder={translate('simple.search.orgadmin')} source='name' resettable alwaysOn />
    </Filter>
  );
}

const NoneActions = props => {
  if (localStorage.getItem('roles') === 'orgadmin') {
    return <TopToolbar />
  } else {
    return <TopToolbar>
      <CreateButton />
      <ExportButton />
      <DeleteButton
        undoable={false}   {...props}
        confirmTitle='simple.notify.confirmTitle'
        confirmContent='simple.notify.confirmContent'
      />
    </TopToolbar>

  }
}

const hideCheckBoxes = props => {
  if (localStorage.getItem('roles') === 'orgadmin') {
    return false
  } else {
    return <BulkDeleteButton
      undoable={false}
      confirmTitle='simple.notify.confirmTitle'
      confirmContent='simple.notify.confirmContent'
      {...props}
    />
  }
}

const goToOrgAdminPage = (e) => {
  console.log('cds',e.email)
  // localStorage.removeItem('auth');
  // localStorage.removeItem('permissions');
  // localStorage.removeItem('roles');
  // localStorage.removeItem('email');
  var data = JSON.stringify({ email: e.email })

  console.log("🚀 ~ goToOrgAdminPage ~ data:", data);

  axios.post(process.env.REACT_APP_API_URL + `/orgadmins/login`, data, CONFIG).then((res) => {
  })
};

const OrgAdminList = (props) => (
  <List {...props} title='simple.listTitles.orgadmin' actions={<NoneActions />} bulkActionButtons={hideCheckBoxes()} filters={< OrgAdminFilter />}
    sort={{ field: 'updated', order: 'DESC' }}
    filter={JSON.parse(localStorage.getItem('auth')).role ? (JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' ?
      {
        id: JSON.parse(localStorage.getItem('auth'))?.orgadminid
      } : {}) : {}} >
    <Datagrid rowClick='edit'>
      <TextField source='name' />
      <EmailField source='email' />
      <TextField source='phonenumber' />
      <TextField source='state' />
      <TextField source='isdoctor' />
      <TextField source='customername' />
      <TextField source='updated' />
      {/* <FunctionField
  label="Preview"
  onChange={goToOrgAdminPage}
  render={record => (
    <a href="#" onClick={() => goToOrgAdminPage(record)}>
      View
    </a>
  )}
/> */}
    </Datagrid>
  </List >
);


export default OrgAdminList;