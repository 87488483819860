export const englishMessages = {
    simple: {
        module: {
            organization: 'Organization',
            customers: 'Customers',
            doctors: 'Doctors',
            FAQ: 'FAQ',
            orgAdmins: 'Organization admins',
            patients: 'Patients',
            settingsNotifications: 'Settings',
            subscriptionPlan: 'Subscription plan',
            transactionHistory: 'Lite accounts',
            AUDITLOG:'Audit log',
            AchievedLog:'Archieved audit log',
            Achievedpatients: 'Archieved Lite accounts'

        },
        action: {
            close: 'Close',
            resetViews: 'Reset views',
        },
        validation: {
            maxLength50: 'Please enter a value shorter or equal to 50 characters.',
            maxLength100: 'Please enter a value shorter or equal to 100 characters.',
            maxLength500: 'Please enter a value shorter or equal to 500 characters.',
            maxLength20: 'Please enter a value shorter or equal to 20 characters.',
            maxLength12: 'Please enter a value shorter or equal to 12 characters.',
            maxLength15: 'Please enter a value shorter or equal to 15 characters.',
            greaterorEqual8: 'Please enter a value greater or equal to 8 characters',
            alphaOrHyphen: 'Please enter only -(hyphen) or alphanumeric values.',
            validEmail: 'Please enter valid e-mail address.',
            onlyNumbers: 'Please enter a valid value (numbers only).',
            validPassword: 'Please add password containing at least 1 uppercase, 1 lowercase, 1 digit, 1 special character and have a length of at least of 8.',
            doctorPatientNotSame: 'Doctor and patient cannot be same.',
            operationSuccessful:'Operation successful!',
            dataNotUploaded:'Something went wrong, data is not uploaded.',
            dataIsNotValid:'File input data is wrong',
            notificationSuccessful:'Notification sent successfully!'
        },
        notify: {
            save_customer: 'New customer created',
            save_doctor: 'New doctor created',
            save_FAQ: 'New FAQ created',
            save_orgadmin: 'New organization admin created',
            save_patient: 'New patient created',
            save_settings: 'Settings are saved',
            confirmTitle: 'Delete %{name}',
            confirmTitleFAQ: 'Delete FAQ',
            confirmTitleAUDITLOG:'Delete Audit log',
            confirmTitleAchievedLog:'Delete Achieved audit Log',
            confirmContent: 'Are you sure? You will not be able to revert this!'
        },
        dashboard: {
            title: 'Name',
            adminname : 'Kunto Solutions Oy',
            customercode: 'Customer code',
            product: 'Product',
            optInUse: 'Options in use',
            state: 'Product state',
            userGuides: 'User guides',
            guieForDocOrgAd: 'Kunto User Guide for Doctors and Organization Admins',
            guideForpPtients: 'Kunto User Guide for Patients',
            termsOfService: 'Terms of service',
            privacyPolicy: 'Privacy policy'
        },
        search: {
            customer: 'Customer Name',
            FAQ: 'FAQ Question',
            doctor: 'Doctor Name',
            orgadmin: 'Organization Admin Name',
            patient: 'Doctor Name',
            liteAccounts: 'Lite Accounts Name',
            subscriptionPlan: 'Subscription plan Name',
            email: 'Lite Accounts Email',
            AUDIT:'Audit',
            AchievedLog: 'Achieved Audit Log'
        },
        createTitles: {
            customer: 'Create new Customer',
            FAQ: 'Create new FAQ',
            doctor: 'Create new Doctor',
            orgadmin: 'Create new OrgAdmin',
            patient: 'Create new Patient',
            subscriptionPlan: 'Create new Subscription plan',
        },
        editTitles: {
            customer: 'Edit Customers',
            FAQ: 'Edit FAQ',
            doctor: 'Edit Doctors',
            orgadmin: 'Edit OrgAdmins',
            patient: 'Edit Patients',
            subscriptionPlan: 'Edit Subscription plan',
        },
        listTitles: {
            customer: 'List of Customers',
            FAQ: 'List of FAQs',
            doctor: 'List of Doctors',
            orgadmin: 'List of OrgAdmins',
            patient: 'List of Patients',
            subscriptionPlan: 'List of Subscription plans',
            liteAccounts: 'List of Lite Accounts',
            tooltips: {
                trendRedArrow: '6 min activity level is >5% lower than the avg. 6 min. activity level of last week',
                trendGreenArrow: '6 min. activity level is increased > 5% from the avg. 6 min. activity level of last week',
                trendyellowArrow: '6 min. activity is on same level as the avg. 6 min. activity level of last week (within +-5%)',
                noTrendActivity: 'No activity level recorded for the last week',
                ACLevelRedRound: 'Latest 6 min. activity level is 35% or more below the pre-op 6 min. activity level',
                ACLevelGreenRound: 'Latest 6 min. activity level is over the pre-op 6 min. activity level',
                ACLevelYellowRound: 'Pre-op level = average daily 6 min activity of the month before the op.',
                ACLevelNoActivity: 'No activity level or pre-op level recorded yet'
            },
            AUDITLOG:'List of Audit logs',
            AchievedLog:'List of Archieved audit logs',
            Achievedpatients:'List of Archieved Lite accounts'
        },
        selectOptionValues: {
            name: 'Name',
            email: 'Email',
            phonenumber: 'Phone number',
            state: 'State',
            updated: 'Updated',
            pseudonym: 'Pseudonym',
            doctor: 'Doctor',
            customercode: 'Customer code',
            dashboard: 'Dashboard',
            daysfromoperation: 'Days from op.',
            activitylevel: 'Activity level',
            trend: 'Trend',
            sensors: 'Automatically extract activity data from smartphone sensors and/or connected wearables and send it to Kunto Cloud',
            reportPain: 'Report pain',
            ShowvideosAnimations: 'Show videos/animations of exercises to support recovery',
            activityPainData: 'Share activity & pain data with family, friends & carers',
            GetNotifications: 'Get notifications and reminders',
            ViewFAQs: 'View FAQ’s and instructional videos / user guides'
        },
    },
    resources: {
        customers: {
            name: 'Customer |||| Customers',
            edit: {
                name: 'Edit of Customers',
            },
            fields: {
                name: 'Name',
                customercode: 'Customer code',
                address: 'Address',
                city: 'City',
                postalcode: 'Postal code',
                country: 'Country',
                product: 'Product',
                version: 'Version',
                androidversion: 'Android Application Version',
                iosversion:'iOS Application Version',
                options: 'Options',
                logo: 'Logo',
                color: 'Color',
                state: 'State',
                updated: 'Updated',
                sendnotification: 'Send notification',
                sharing:'Enable sharing functionality',
                contact:'Enable contact functionality',
                phonenumber: 'Phone number',
                smsphonenumber:'Sms number'
            },
            placeholder: {
                logo: 'Drop your file here',
                allowedFileTypes: 'Allowed file types: .jpg, .jpeg, .png, .gif'
            },

        },
        doctors: {
            name: 'Doctor |||| Doctors',
            fields: {
                name: 'Name',
                email: 'Email',
                password: 'Password',
                phonenumber: 'Phone number',
                regnumber: 'Reg number',
                customerid: 'Customer',
                customername: 'Customer name',
                isorgadmin: 'Is orgadmin',
                state: 'State',
                updated: 'Updated'
            },
        },
        orgadmins: {
            name: 'Organization admin |||| Organization admins',
            fields: {
                name: 'Name',
                email: 'Email',
                password: 'Password',
                phonenumber: 'Phone number',
                customerid: 'Customer',
                state: 'State',
                updated: 'Updated',
                isdoctor: 'Is doctor',
                customername: 'Customer name',
                removerestriction: 'Remove restriction',
                patientdetails: 'Patient details (if none of below Patient details is selected then all details will be displayed)',
                nameremoverestriction: 'Remove access restrictions to patient data'
            },
        },
        patients: {
            name: 'Patient |||| Patients',
            fields: {
                doctorid: 'Doctor',
                isresearchuser:'Is Research User ?',
                patient: {
                    pseudonym: 'Pseudonym',
                    name: 'Name',
                    phonenumber: 'Phone number',
                    email: 'Email',
                    state: 'State',
                    updated: 'Updated',
                    daysfromoperation: 'Days from op.',
                    activitylevel: 'Activity level',
                    trend: 'Trend'
                },
            },
            popup: {
                sendnotification: 'Send notification',
                enternotificationmsg: 'Enter notification message',
                enternotificationmsgtoliteaccountuser:'Enter notification message for Lite account user',
                enternotificationmsgtoalluser:'Enter notification message for all user',
                send: 'Send',
                close: 'Close',
                sendToAll:'Send Notification to all'
            }
        },
        transactionHistory: {
            pseudonym: 'Pseudonym',
            name: 'Name',
            phonenumber: 'Phone number',
            email: 'Email',
            state: 'State',
            updated: 'Updated',
            daysfromoperation: 'Days from op.',
            activitylevel: 'Activity level',
            trend: 'Trend',
            subscriptionType: 'Subscription type',
            daysLeft: 'Days left',
            subscriptionStartDate: 'Subscription start date',
            subscriptionEndDate: 'Subscription end date',
            resetPassword:'Reset password',
            daysLeftTrial:'Days left for trial / renewal',
            createdAt:'CreatedAt',
            subscriptionstatus:'Subscription status',
            dashboard:'Dashboard'
        },
        FAQs: {
            name: 'FAQ |||| FAQs',
            fields: {
                question: 'Question',
                answer: 'Answer',
                state: 'State',
                updated: 'Updated',
                english:'English',
                german:'German',
                finnish:'Finnish'
            },
        },
        notificationSettings: {
            name: 'Settings',
            fields: {
                activitydatahasnotbeenreceived: 'Patient hasn’t reported Activity',
                patientreportedpainmedication: `Patient hasn’t reported pain & medication`,
                patientansweredthequestionnaires: 'Patient hasn’t answered the questionnaires',
                patientperformedthetestexercises: 'Patient hasn’t performed the test exercises',
                activitydatahasnotbeenreceivedmsg: 'Message',
                patientperformedthetestexercisesmsg: 'Message',
                patientreportedpainmedicationmsg: 'Message',
                patientansweredthequestionnairesmsg: 'Message',
                patientreportedpainmedicationinterval: 'Interval',
                patientansweredthequestionnairesinterval: 'Interval',
                patientperformedthetestexercisesinterval: 'Interval',
                activitydatahasnotbeenreceivedinterval: 'Interval',
                version: 'Version',
                reactadminversion: 'React-admin Version',
                androidversion: 'Android Application Version',
                iosversion:'iOS Application Version',
                shoulderVidInstructionUrl: 'Shoulder video instruction url',
                backVidInstructionUrl: 'Back video instruction url',
                wristVidInstructionUrl: 'Wrist video instruction url',
                kneeVidInstructionUrl: 'Knee video instruction url',
                apSharing: 'Enable in-app sharing',
                ankleVidInstructionUrl:'Ankle video instruction url'
            },
        },
        customlogin: {
            email: 'Enter your email',
            verificationcode: 'Send verification code',
            pin: 'Enter 4 digit verification code sent to your mail',
            resndcodein: 'Resend verification code in',
            resend: 'Resend verification code',
            verify: 'Verify'
        }
    },
    ra: {
        action: {
            add_filter: 'Add filter',
            add: 'Add',
            back: 'Go Back',
            bulk_actions: '1 item selected |||| %{smart_count} items selected',
            cancel: 'Cancel',
            clear_input_value: 'Clear value',
            clone: 'Clone',
            confirm: 'Confirm',
            create: 'Create',
            create_item: 'Create %{item}',
            delete: 'Delete',
            edit: 'Edit',
            export: 'Export',
            list: 'List',
            refresh: 'Refresh',
            remove_filter: 'Remove this filter',
            remove: 'Remove',
            save: 'Save',
            search: 'Search',
            select_all: 'Select all',
            select_row: 'Select this row',
            show: 'Show',
            sort: 'Sort',
            undo: 'Undo',
            unselect: 'Unselect',
            expand: 'Expand',
            close: 'Close',
            open_menu: 'Open menu',
            close_menu: 'Close menu',
            update: 'Update',
            move_up: 'Move up',
            move_down: 'Move down',
            open: 'Open',
            toggle_theme: 'Toggle Theme',
        },
        boolean: {
            true: 'Yes',
            false: 'No',
            null: ' ',
        },
        page: {
            create: 'Create %{name}',
            dashboard: 'Dashboard',
            edit: '%{name} #%{id}',
            error: 'Something went wrong',
            list: '%{name}',
            loading: 'Loading',
            not_found: 'Not Found',
            show: '%{name} #%{id}',
            empty: 'No %{name} yet.',
            invite: 'Do you want to add one?',
        },
        input: {
            file: {
                upload_several:
                    'Drop some files to upload, or click to select one.',
                upload_single: 'Drop a file to upload, or click to select it.',
            },
            image: {
                upload_several:
                    'Drop some pictures to upload, or click to select one.',
                upload_single:
                    'Drop a picture to upload, or click to select it.',
            },
            references: {
                all_missing: 'Unable to find references data.',
                many_missing:
                    'At least one of the associated references no longer appears to be available.',
                single_missing:
                    'Associated reference no longer appears to be available.',
            },
            password: {
                toggle_visible: 'Hide password',
                toggle_hidden: 'Show password',
            },
        },
        message: {
            about: 'About',
            are_you_sure: 'Are you sure?',
            bulk_delete_content:
                'Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?',
            bulk_delete_title:
                'Delete %{name} |||| Delete %{smart_count} %{name}',
            bulk_update_content:
                'Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items?',
            bulk_update_title:
                'Update %{name} |||| Update %{smart_count} %{name}',
            delete_content: 'Are you sure you want to delete this item?',
            delete_title: 'Delete %{name} #%{id}',
            details: 'Details',
            error:
                "A client error occurred and your request couldn't be completed.",
            invalid_form: 'The form is not valid. Please check for errors',
            loading: 'The page is loading, just a moment please',
            no: 'No',
            not_found:
                'Either you typed a wrong URL, or you followed a bad link.',
            yes: 'Yes',
            unsaved_changes:
                "Some of your changes weren't saved. Are you sure you want to ignore them?",
        },
        navigation: {
            no_results: 'No results found',
            no_more_results:
                'The page number %{page} is out of boundaries. Try the previous page.',
            page_out_of_boundaries: 'Page number %{page} out of boundaries',
            page_out_from_end: 'Cannot go after last page',
            page_out_from_begin: 'Cannot go before page 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
            partial_page_range_info:
                '%{offsetBegin}-%{offsetEnd} of more than %{offsetEnd}',
            current_page: 'Page %{page}',
            page: 'Go to page %{page}',
            first: 'Go to first page',
            last: 'Go to last page',
            next: 'Go to next page',
            prev: 'Go to previous page',
            previous: 'Go to previous page',
            page_rows_per_page: 'Rows per page:',
            skip_nav: 'Skip to content',
        },
        sort: {
            sort_by: 'Sort by %{field} %{order}',
            ASC: 'ascending',
            DESC: 'descending',
        },
        auth: {
            auth_check_error: 'Please login to continue',
            user_menu: 'Profile',
            username: 'Username',
            password: 'Password',
            sign_in: 'Sign in',
            sign_in_error: 'Authentication failed, please retry',
            logout: 'Logout',
        },
        notification: {
            updated: 'Element updated |||| %{smart_count} elements updated',
            created: 'Element created',
            deleted: 'Element deleted |||| %{smart_count} elements deleted',
            bad_item: 'Incorrect element',
            item_doesnt_exist: 'Element does not exist',
            http_error: 'Server communication error',
            data_provider_error:
                'dataProvider error. Check the console for details.',
            i18n_error:
                'Cannot load the translations for the specified language',
            canceled: 'Action cancelled',
            logged_out: 'Your session has ended, please reconnect.',
            not_authorized: "You're not authorized to access this resource.",
        },
        validation: {
            required: 'Required',
            minLength: 'Must be %{min} characters at least',
            maxLength: 'Must be %{max} characters or less',
            minValue: 'Must be at least %{min}',
            maxValue: 'Must be %{max} or less',
            number: 'Must be a number',
            email: 'Must be a valid email',
            oneOf: 'Must be one of: %{options}',
            regex: 'Must match a specific format (regexp): %{pattern}',
        },
        saved_queries: {
            label: 'Saved queries',
            query_name: 'Query name',
            new_label: 'Save current query...',
            new_dialog_title: 'Save current query as',
            remove_label: 'Remove saved query',
            remove_label_with_name: 'Remove query "%{name}"',
            remove_dialog_title: 'Remove saved query?',
            remove_message:
                'Are you sure you want to remove that item from your list of saved queries?',
            help: 'Filter the list and save this query for later',
        },
    },
};

export default englishMessages;
