import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardActions } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import {
  useQuery,
  ListContextProvider, useTranslate
} from 'react-admin';
import bgrImg from "./kunto-background.jpg";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    position: "top",
    height: "100%",
  },
  media: {
    height: "20%",
    width: "20%",
    objectFit: "contain"

  }
});

export const NonInput = React.memo(function NonInput({ children }) {
  return children;
});

const Organization = (props) => {
  const classes = useStyles();

  const [customers, setCustomers] = useState([]);

  const { data: customer } = useQuery({
    type: 'get',
    resource: 'customers',
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'name', order: 'ASC' },
      filter: JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' ?
        {
          id: JSON.parse(localStorage.getItem('auth'))?.customerid
        } : {
        },
    },
  });

  useEffect(() => {
    if (customer) {
      setCustomers(customer);
    }
  }, [customer]);
  const translate = useTranslate();
  return (
    <ListContextProvider value="">
      {/* <NonInput> */}
      <Card className={classes.root}>
        {(localStorage.getItem('roles')) !== ('undefined') ?
          (customer ?
            (customer[0].logo ? <CardMedia
              component="img"
              className={classes.media}
              src={customer[0].logo.src ? customer[0].logo.src : bgrImg}
              title="Organization name"
            />
              : null)
            : null)
          : null}
        <CardContent>
              {/* {console.log('customer',customer)}
          <Typography gutterBottom variant="h5" component="h2">
            {translate('simple.dashboard.title')}
          </Typography> */}
          {(localStorage.getItem('roles')) !== ('undefined') ?
            <Typography Typography gutterBottom variant="h5" component="h2">{translate('simple.dashboard.title')}: {customer ? customer[0].name : null}</Typography> : <Typography Typography gutterBottom variant="h5" component="h2">{translate('simple.dashboard.adminname')}</Typography>}

          {(localStorage.getItem('roles')) !== ('undefined') ?
            <Typography Typography gutterBottom variant="h6" component="h2">{translate('simple.dashboard.customercode')}: {customer ? customer[0].customercode : null}</Typography> : null}

          {(localStorage.getItem('roles')) !== ('undefined') ?
            <Typography gutterBottom variant="h6" component="h2">{translate('simple.dashboard.product')}: {customer ? customer[0].product + (customer[0].version ? ' ' + customer[0].version : '') : null}</Typography> : null}

          {(localStorage.getItem('roles')) !== ('undefined') ?
            <Typography gutterBottom variant="h6" component="h2">{translate('simple.dashboard.optInUse')}: {customer ? customer[0].options.join(',') : null}</Typography> : null}

          {(localStorage.getItem('roles')) !== ('undefined') ?
            <Typography gutterBottom variant="h6" component="h2">{translate('simple.dashboard.state')}: {customer ? customer[0].state : null}</Typography> : null}

          {(localStorage.getItem('roles')) !== ('undefined') ?
            <Typography gutterBottom variant="h6" component="h2">{translate('simple.dashboard.userGuides')}:</Typography> : null}

{
customer?.forEach(customerItem => {
  customerItem.linkContent?.forEach(linkContentItem => {
    const link = linkContentItem.link;
    const content = linkContentItem.content;
    console.log(`Link: ${link}, Content: ${content}`);
  })
})
}
{customer?.slice(0,1).map((customerItem, index) => (
  <div key={index}>
    {customerItem.linkContent ? customerItem.linkContent.map((linkContentItem, subIndex) => (
      <CardActions key={subIndex}>
        <a href={"//" +linkContentItem.link} target="_blank">
          {'>'}{linkContentItem.content}
        </a>
      </CardActions>
    )):<>
              <CardActions >
            <a href='https://kuntosolutions.com/en/user-guide-doctors-admins/' target="_blank" rel="noreferrer" >
              {'>'}{translate('simple.dashboard.guieForDocOrgAd')}
            </a>
          </CardActions>
          <CardActions>
            <a href='https://kuntosolutions.com/en/user-guide-doctors-admins/' target="_blank" rel="noreferrer" >
              {'>'}{translate('simple.dashboard.guideForpPtients')}</a>
          </CardActions>

          <CardActions>
            <a href='https://kuntosolutions.com/en/terms-and-conditions/' target="_blank" rel="noreferrer" >
              {translate('simple.dashboard.termsOfService')}</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href='https://kuntosolutions.com/en/privacy-policy/' target="_blank" rel="noreferrer" >
              {translate('simple.dashboard.privacyPolicy')}</a>
          </CardActions></>}
  </div>
))}

        </CardContent>

      </Card >
      {/* </NonInput> */}
    </ListContextProvider>
  );


};


export default Organization