import React, { useState, useEffect } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  SelectInput,
  PasswordInput,
  BooleanInput,
  CheckboxGroupInput,
  useQuery,
  required,
  regex,
  maxLength,
  email,
  minLength,
  Toolbar, SaveButton,
  DeleteButton
} from 'react-admin';
import Constants from '../Constants';

const OrgAdminEdit = (props) => {
  const [customers, setCustomers] = useState([]);
  const { data: customer } = useQuery({
    type: 'getList',
    resource: 'customers',
    payload: {
      sort: { field: 'name', order: 'ASC' },
      filter: {
        state: Constants.ACTIVE
      }
    },
  });

  useEffect(() => {
    if (customer) {
      setCustomers(customer.map((d) => ({ id: d.id, name: d.name })));
    }
  }, [customer]);

  const validateName = [required(), maxLength(50, 'simple.validation.maxLength50')];
  const validateEmail = [required(), email('simple.validation.validEmail'), maxLength(100, 'simple.validation.maxLength100')];
  // const validatePassword = [required(), maxLength(12, 'simple.validation.maxLength12'),
  // minLength(8, 'simple.validation.greaterorEqual8'),
  // //eslint-disable-next-line
  // regex(/^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,12}$/, 'simple.validation.validPassword')];
  const validatePhoneNumber = [required(), maxLength(20, 'simple.validation.maxLength20'),
  regex(/^[0-9]+$/, 'simple.validation.onlyNumbers')];
  const validateCustomer = [required()];

  const isDisable = () => {
    if (localStorage.getItem('roles') === 'orgadmin') {
      return true
    }
    else {
      return false
    }
  }

  const NoneActions = props => {
    if (localStorage.getItem('roles') === 'orgadmin') {
      return <div />
    } else {
      return <DeleteButton
        undoable={false}
        confirmTitle='simple.notify.confirmTitle'
        confirmContent='simple.notify.confirmContent'
      />
    }
  }

  const EditToolbar = props => {
    if (localStorage.getItem('roles') === 'orgadmin') {
      return false
    }
    else {
      return <div className="deleteButton"> <Toolbar {...props} >
        <SaveButton disabled={props.pristine} />
        <DeleteButton
          undoable={false}
          confirmTitle='simple.notify.confirmTitle'
          confirmContent='simple.notify.confirmContent'
        />
      </Toolbar>
      </div>
    }
  };

  const transform = data => ({
    ...data,
    updated: new Date().toLocaleString(),
  })

  return (
    <Edit {...props} actions={<NoneActions />} title='simple.editTitles.orgadmin' undoable={false} transform={transform}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput disabled={isDisable()} source="name" validate={validateName} />
        <TextInput disabled={isDisable()} source="email" validate={validateEmail} />
        {/* <PasswordInput disabled={isDisable()} source="password" validate={validatePassword} /> */}
        <TextInput disabled={isDisable()} source="phonenumber" validate={validatePhoneNumber} />
        <SelectInput disabled={isDisable()} source='customerid' choices={customers} validate={validateCustomer} />
        <RadioButtonGroupInput disabled={isDisable()} source='state' choices={[{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }]} />
        <TextInput disabled source='updated' initialValue={new Date().toLocaleString()} />
        <BooleanInput disabled={isDisable()} source="isdoctor" />
        <CheckboxGroupInput source="patientdetails" choices={[
          { id: 'pseudonym', name: 'simple.selectOptionValues.pseudonym' },
          { id: 'name', name: 'simple.selectOptionValues.name' },
          { id: 'phonenumber', name: 'simple.selectOptionValues.phonenumber' },
          { id: 'email', name: 'simple.selectOptionValues.email' },
          { id: 'doctor', name: 'simple.selectOptionValues.doctor' },
          { id: 'customercode', name: 'simple.selectOptionValues.customercode' },
          { id: 'dashboard', name: 'simple.selectOptionValues.dashboard' },
          { id: 'state', name: 'simple.selectOptionValues.state' },
          { id: 'updated', name: 'simple.selectOptionValues.updated' },
          { id: 'daysfromoperation', name: 'simple.selectOptionValues.daysfromoperation' },
          { id: 'activitylevel', name: 'simple.selectOptionValues.activitylevel' },
          { id: 'trend', name: 'simple.selectOptionValues.trend' },
        ]} />
        <BooleanInput disabled={isDisable()} source="removerestriction" />
      </SimpleForm>
    </Edit>
  );
};

export default OrgAdminEdit;