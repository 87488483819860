import React from 'react';
import { List, useTranslate, Filter, Datagrid, TextField, SearchInput, TopToolbar, DeleteButton, BulkDeleteButton, CreateButton, ExportButton } from 'react-admin';

// ------------- filter component -------------
const AchievedLogFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchInput placeholder={translate('simple.search.AchievedLog')} source='operation' resettable alwaysOn />
    </Filter>
  );
}

const MyBulkActionButtons = props => {
  if (JSON.parse(localStorage.getItem('auth')).role === 'doctor') {
    return false
  } else {
    return <BulkDeleteButton
      undoable={false}
      confirmTitle='simple.notify.confirmTitleAchievedLog'
      confirmContent='simple.notify.confirmContent'
      {...props}
    />
  }
}

const MyActions = props => {
  if (JSON.parse(localStorage.getItem('auth')).role === 'doctor') {
    return <TopToolbar>
      <ExportButton />
    </TopToolbar>
  } else {
    return <TopToolbar>
      <ExportButton />
      <DeleteButton
        undoable={false}
        confirmTitle='simple.notify.confirmTitleAchievedLog'
        confirmContent='simple.notify.confirmContent'
      />
    </TopToolbar>
  }

}

const postRowClick = (id, resource, record) => {
  return (record.customerid === "" && (JSON.parse(localStorage.getItem('auth')).role === 'orgadmin'
    || JSON.parse(localStorage.getItem('auth')).role === 'doctor'))
    ? undefined : 'edit';
}

const AchievedLogList = (props) => {
  return (
    <List {...props} title='simple.listTitles.AchievedLog' actions={<MyActions />} bulkActionButtons={MyBulkActionButtons(props)}
      filters={<AchievedLogFilter />}
    //   filter={
    //     {
    //       customerid: JSON.parse(localStorage.getItem('auth'))?.customerid ? JSON.parse(localStorage.getItem('auth'))?.customerid : ""
    //     }
    //   }
      sort={{ field: 'updated', order: 'DESC' }}>
      <Datagrid rowClick={postRowClick} isRowSelectable={record => record.id === "" &&
        (JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' || JSON.parse(localStorage.getItem('auth')).role === 'doctor') ? false : true} >
        <TextField source='entity_name' />
        <TextField source='operation' />
        <TextField source='oldvalue' />
        <TextField source='newvalue' />
        <TextField source='comment' />
        <TextField source='created' label={'Date'}/>
      </Datagrid>
    </List>

  )
}


export default AchievedLogList;