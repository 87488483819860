import React from 'react';
import { List, useTranslate, Filter, Datagrid, TextField, SearchInput, TopToolbar, DeleteButton, BulkDeleteButton, CreateButton, ExportButton } from 'react-admin';

import './styles.css';
const CustomTextField = ({ record, source, label }) => {
  let text = record[source] || ' ';

  if (typeof text === 'object' && text.title) {
    text = text.title; // Display only the title property of the object
  }

  return (
    <TextField record={record} source={source} label={source}>
      {text.length > 52 ? text.substr(0, 52) + '...' : text}
    </TextField>
  );
};
// ------------- filter component -------------
const AUDITLOGFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchInput placeholder={translate('simple.search.AUDIT')} source='changedby' resettable alwaysOn />
    </Filter>
  );
}

const MyBulkActionButtons = props => {
  if (JSON.parse(localStorage.getItem('auth')).role === 'doctor') {
    return false
  } else {
    return <BulkDeleteButton
      undoable={false}
      confirmTitle='simple.notify.confirmTitleAUDITLOG'
      confirmContent='simple.notify.confirmContent'
      {...props}
    />
  }
}

const MyActions = props => {
  if (JSON.parse(localStorage.getItem('auth')).role === 'doctor') {
    return <TopToolbar>
      <ExportButton />
    </TopToolbar>
  } else {
    return <TopToolbar>
      <ExportButton />
      <DeleteButton
        undoable={false}
        confirmTitle='simple.notify.confirmTitleAUDITLOG'
        confirmContent='simple.notify.confirmContent'
      />
    </TopToolbar>
  }

}

const postRowClick = (id, resource, record) => {
  return (record.customerid === "" && (JSON.parse(localStorage.getItem('auth')).role === 'orgadmin'
    || JSON.parse(localStorage.getItem('auth')).role === 'doctor'))
    ? undefined : 'edit';
}

const AUDITLOGList = (props) => {
  return (
    <List {...props} title='simple.listTitles.AUDITLOG' actions={<MyActions />} bulkActionButtons={MyBulkActionButtons(props)}
      filters={<AUDITLOGFilter />}
    //   filter={
    //     {
    //       customerid: JSON.parse(localStorage.getItem('auth'))?.customerid ? JSON.parse(localStorage.getItem('auth'))?.customerid : ""
    //     }
    //   }
      sort={{ field: 'created', order: 'DESC' }}>
      <Datagrid rowClick={postRowClick} isRowSelectable={record => record.id === "" &&
        (JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' || JSON.parse(localStorage.getItem('auth')).role === 'doctor') ? false : true} >
        <TextField source='entity_name' />
        <TextField source='operation' />
        <TextField source='oldvalue'  />
        <CustomTextField source='newvalue'/>
        <TextField source='updatedby' label={'changedby'}/>
        <TextField source='changedon'/>
        <TextField source='comment' />
        <TextField source='displayDate' label={'Date'}/>

      </Datagrid>
    </List>

  )
}


export default AUDITLOGList;