import { useState, useEffect } from 'react';
import { useLogin, useNotify, useTranslate } from 'react-admin';
import axios from 'axios';
import { Box, Typography, Link as NavLink } from '@material-ui/core'
import { CONFIG } from './Constants';
const Login = ({expiresIn=300}) => {

    const [isMailSent, setIsMailSent] = useState(false);
    const [email, setEmail] = useState('');
    const login = useLogin();
    const notify = useNotify();
    const translate = useTranslate();
    const [password, setPassword] = useState(''); 
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const [timeLeft,setTimeLeft] = useState(expiresIn);

    const formatTime = (t) => t < 10 ? '0' + t : t;

    useEffect(() => {
        const interval = setInterval(() => {
            const m = Math.floor(timeLeft / 60);
            const s = timeLeft - m * 60;

            setMin(m);
            setSec(s);
            if (m <= 0 && s <= 0) return () => clearInterval(interval);

            setTimeLeft((t) => t - 1);
          }, 1000);

          return () => clearInterval(interval);
    }, [timeLeft]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         const m = Math.floor(timeLeft / 60);
    //         const s = timeLeft - m * 60;

    //         setMin(m);
    //         setSec(s);
    //         if (m <= 0 && s <= 0) return () => clearInterval(interval);

    //         setTimeLeft((t) => t - 1);
    //       }, 1000);

    //       return () => clearInterval(interval);
    // }, [timeLeft]);

    // useEffect(() => {
    //     const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    //     return () => clearInterval(timer);
    // }, [counter])

    const HandleResendOTP = async e => {
        e.preventDefault();
        setTimeLeft(300);
        console.log(e, 'Clicked login ...');
        await axios.post(process.env.REACT_APP_API_URL + `/sendmail`, { email: email }, CONFIG).then((res) => {
            console.log(res, "ress");
        })

        notify('Mail sent!');
    }

    const HandleSendOTP = async e => {
        e.preventDefault();
        console.log(email, "credentials");
        console.log(e, 'Clicked login ...');
        await axios.post(process.env.REACT_APP_API_URL + `/sendmail`, { email: email }, CONFIG).then((res) => {
            setIsMailSent(true);
            localStorage.setItem('email', email);
            notify('mail sent !');

        }).catch((e => {
            //  throw new Error('Please enter correct email !');
            alert('Please enter correct email !');
            // notify('Pmail sent !');
        }
        ))


    }

    const HandleLogin = async e => {
        e.preventDefault();

        login({ username: email, password: password })
    .then(res => console.log(res, "response"))
    .catch(error => {
        // notify("Invalid OTP")
        alert('Error: ' + error.message); // Displaying the error message in an alert
    });

    }

    return <>
        {(!isMailSent || isMailSent == "") ?
            <div className="login-ui">
                <div className="login-card">
                    <div className="icon-lock">
                        <div className="lock-svg">
                            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                <path
                                    d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z">
                                </path>
                            </svg>
                        </div>
                    </div>
                    <form noValidate="" onSubmit={HandleSendOTP}>
                        <div className="login-spacing">
                            <div className="mt-top">
                                <div className="input-field-custiom">
                                    <Box color="text.secondary">
                                        <Typography variant="body2">
                                            {translate('resources.customlogin.email')}
                                        </Typography>
                                    </Box>
                                    <div className="input-form-control">
                                        <input required id="email" name="email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="login-button">
                            <button className="submit-btn" tabIndex="0" type="submit">
                                <span className="MuiButton-label">{translate('resources.customlogin.verificationcode')}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            :
            <div className="login-ui">
                <div className="login-card">
                    <div className="icon-lock">
                        <div className="lock-svg">
                            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                <path
                                    d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z">
                                </path>
                            </svg>
                        </div>
                    </div>
                    <form noValidate="" onSubmit={HandleLogin}>
                        <div className="login-spacing">
                            <div className="mt-top">
                                <div className="input-field-custiom">
                                    <Box color="text.secondary">
                                        <Typography variant="body2">
                                            {translate('resources.customlogin.pin')}
                                        </Typography>
                                    </Box>

                                    <div className="input-form-control">
                                        <input required maxLength={4} id="otp" name="otp" type="number" value={password} onChange={e => setPassword(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="login-button">
                            <button className="submit-btn" tabIndex="0" type="submit">
                                <span className="MuiButton-label" >
                                    {translate('resources.customlogin.verify')}
                                </span>
                            </button>
                        </div>

                    </form>
                    <Box mt={3} ><Typography fontWeight={500} align="center" color='textSecondary'>
                        {translate('resources.customlogin.resndcodein')}
                        <span style={{ color: "green", fontWeight: "bold" }}> {formatTime(min)}:{formatTime(sec)}</span> 
                       </Typography> </Box> 
                    {
                        (formatTime(sec == 0)) &&( (min == 0 ))&& timeLeft == 0?
                            <Typography align="center">
                                <NavLink to="Signup">
                                    <span style={{ marginLeft: "5px" }} onClick={HandleResendOTP}>
                                        {translate('resources.customlogin.resend')}
                                    </span>
                                </NavLink>
                            </Typography>
                             : null
                            }
                </div>
            </div>
        }
    </>
}

export default Login;
