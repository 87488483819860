import React from 'react';
import { List, Filter, Datagrid, TextField, SearchInput, SingleFieldList, TopToolbar, DeleteButton, BulkDeleteButton, CreateButton, ExportButton } from 'react-admin';
import Chip from '@material-ui/core/Chip'
import { useTranslate } from 'react-admin'
// ------------- filter component -------------
const CustomerFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <SearchInput placeholder={translate('simple.search.customer')}
      source = 'name' resettable alwaysOn />
    </Filter>
  );
}

const TextArrayField = ({ record, source }) => {
  const array = record[source]
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div />
  } else {
    return (
      <>
        {array.map(item => item !== '' ? <Chip label={item} key={item} /> : null)}
      </>
    )
  }
}
TextArrayField.defaultProps = { addLabel: true }

const MyBulkActionButtons = props => (
  <BulkDeleteButton
    undoable={false}
    confirmTitle='simple.notify.confirmTitle'
    confirmContent='simple.notify.confirmContent'
    {...props}
  />
)

const MyActions = props => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
    <DeleteButton
      undoable={false}
      confirmTitle='simple.notify.confirmTitle'
      confirmContent='simple.notify.confirmContent'
    />
  </TopToolbar>
)

const CustomerList = (props) => (
  <List {...props} title='simple.listTitles.customer' actions={<MyActions />} bulkActionButtons={<MyBulkActionButtons />} filters={<CustomerFilter />} sort={{ field: 'updated', order: 'DESC' }} >
    <Datagrid rowClick='edit' >
      <TextField source='name' />
      <TextField source='customercode' />
      <TextField source='address' />
      <TextField source='city' />
      <TextField source='postalcode' />
      <TextField source='country' />
      <TextField source='product' />
      <TextField source='version' />
      <TextArrayField source="options" sortable={false}>
        <SingleFieldList>
          <TextField source="id" />
        </SingleFieldList>
      </TextArrayField>
      <TextField source='state' />
      <TextField source='updated' />
    </Datagrid>
  </List>
);

export default CustomerList;