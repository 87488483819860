
import {
  Edit,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  SelectInput,
  required,
  maxLength,
  PasswordInput,
  DateInput,
  useTranslate,
  email,
  NumberInput,
  FileInput,
  FileField,
  Button
} from 'react-admin';
import Papa from 'papaparse';
import axios from 'axios';
import { CONFIG } from './../Constants';
import { toast, ToastContainer } from 'react-toastify';
import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

export const convertMidnightToMidnightInGMT0ForTheSameDay = date => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};




const TransactionHistoryEdit = (props) => {
  const translate = useTranslate();
  const [selectedValue, setSelectedValue] = useState('walking');
  const [fileParsedData, setParsedData] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  const [roles, getRoles] = useState()


  const MyFileUploader = ({ onFileUpload, data }) => {

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      console.log("cd")
      setParsedData(null); // Reset fileParsedData
      setSelectedFileName(file ? file.name : null);
      // Read the file content as text
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target.result;

        // Parse the CSV data using PapaParse
        const parsedData = Papa.parse(csvData, { header: true });

        // Call the onFileUpload callback with the parsed data
        // onFileUpload(parsedData.data);
        setParsedData(parsedData.data)
      };
      reader.readAsText(file);
    };

    return (
      <div style={{ marginBottom: '25px', marginTop: '15px' }}>
        <h2>Import Data</h2>
        <div>
          <select value={selectedValue} onChange={handleDropdownChange} style={{ marginBottom: '35px' }}>
            <option value="walking">Walking Activity</option>
            <option value="patientInfo">Patient Basic Info</option>
            <option value="stressquestion">Stress Question</option>
            <option value="defaultquestion">Default Question</option>
          </select>
        </div>
        <input type="file" accept=".csv" onChange={handleFileChange} id="fileInput" style={{ display: 'none' }} />
        <label htmlFor="fileInput" style={{
          cursor: 'pointer',
          backgroundColor: '#CECED1',
          color: 'white',
          padding: '10px 15px',
          borderRadius: '5px',
          border: 'none',
          fontWeight: 'bold',
          marginTop: '25px'
        }}>
          Choose a file
        </label>
        <p>
          {selectedFileName ? (
            `Selected File: ${selectedFileName}`
          ) : (
            'No file chosen'
          )}
        </p>
        <Button label='submit' onClick={() => { handleFileUpload(fileParsedData) }} style={{ backgroundColor: '#CECED1' }}></Button>
        <div style={{ marginTop: '15px' }}>
          <ToastContainer style={{ marginTop: '30px' }} />
        </div>
        <hr></hr>
      </div>
    );
  };


  const transform = data => ({
    ...data,
    createdAt:new Date(data.createdAt).toLocaleString(),
    subscriptionDate:new Date(data.subscriptionDate).toLocaleString(),
    subscriptionEndDate: new Date(data.subscriptionEndDate).toLocaleString(),
    //updated: new Date().toLocaleString(),
    doctor: data.doctor != null ? data.doctor : "",
    productPrice: data.productPrice != null ? data.productPrice : "",
    measurements: data.measurements != null ? data.measurements : {},
    questionnaires: data.questionnaires != null ? data.questionnaires : [],
    defaultquestionnaires: data.defaultquestionnaires != null ? data.defaultquestionnaires : []
  })

  const PreviewSubscriptionType = ({ record, source }) => {
    if (record.subscriptionType == "Free trial, 30 days" || record.subscriptionType == null) {
      return <SelectInput validate={required()} record={record} source={source}
        label={translate('resources.transactionHistory.subscriptionType')}
        choices={[{ id: 'Free trial, 30 days', name: 'Free trial, 30 days  ' }]}
      />
    } else {
      return <TextInput record={record} source={source} disabled label={translate('resources.transactionHistory.subscriptionType')} validate={required()} />
    }
  }
  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleFileUpload = async (parsedData) => {
    const currentURL = window.location.href;
    var startIndex = currentURL.indexOf("lite-accounts/") + 14;
    var id = currentURL.substring(startIndex);
    var medicalData;
    console.log("parse Data", parsedData)

    if (selectedValue != "defaultquestion") {
      await axios.get(process.env.REACT_APP_API_URL + `/patients/${id}`, CONFIG).then(async (res) => {

        medicalData = res.data;
        if (selectedValue == 'walking') {
          var headers = ['date', 'dailyTotalStepQuantity', 'hourlyMaxStepsForDay', 'maxOneMinuteSteps', 'maxSixMinuteSteps', 'oneMinuteStepsAvg', 'sixMinutesStepsAvg']
          var new_data = []

          var parsedData_key = Object.keys(parsedData[0])
          var isColumnMatches = true;
          parsedData_key.forEach((key) => {
            if (!headers.includes(key)) {
              isColumnMatches = false;
            }
          })
          // for (const key in parsedData_key){
          //   console.log(key)
          //   if(headers.includes(key)){
          //     console.log("check",key)
          //   }
          //   else {
          //     console.log(`${key} is not in the array!`);
          //   }
          // }
          // const result = headers.reduce((acc, curr) => {
          //   acc[curr] = obj[curr];
          //   return acc;
          // }, {});

          if (isColumnMatches == true) {
            for (var i = 0; i < parsedData.length - 1; i++) {
              var tempObj = {
                _id: convertMidnightToMidnightInGMT0ForTheSameDay(new Date(parsedData[i].date)).getTime(),
                date: parsedData[i].date,
                walkingData: {
                  dailyTotalStepQuantity: Number(parsedData[i].dailyTotalStepQuantity),
                  hourlyMaxStepsForDay: Number(parsedData[i].hourlyMaxStepsForDay),
                  maxOneMinuteSteps: Number(parsedData[i].maxOneMinuteSteps),
                  maxSixMinuteSteps: Number(parsedData[i].maxSixMinuteSteps),
                  oneMinuteStepsAvg: Number(parsedData[i]?.oneMinuteStepsAvg ? parsedData[i]?.oneMinuteStepsAvg : "0"),
                  sixMinutesStepsAvg: Number(parsedData[i]?.sixMinutesStepsAvg ? parsedData[i]?.sixMinutesStepsAvg : "0")
                }
              }
              new_data.push(tempObj)
            }
            if(medicalData.measurements){
              medicalData.measurements.walkingactivity = new_data
            }else{
              medicalData.measurements = {
                walkingactivity : new_data
              }
            }
            
          }
          else {
            toast.error(translate('simple.validation.dataIsNotValid'))
          }
        }
        else if (selectedValue == "patientInfo") {
          var headers = ['id','previousOperations','previousOperations_1','treatmentTarget', 'operationDate', 'gender', 'patientHeight', 'patientWeight', 'patientBirthYear', 'operatingUnitCode', 'dataPermission']
          var parsedData_key = Object.keys(parsedData[0])
          var isColumnMatches = true;
          parsedData_key.forEach((key) => {
            if (!headers.includes(key)) {
              isColumnMatches = false;
            }
          })
          if (isColumnMatches == true) {
            medicalData.patient.treatmentTarget = parsedData[0]?.treatmentTarget
            medicalData.patient.operationDate = parsedData[0]?.operationDate
            medicalData.patient.gender = parsedData[0]?.gender
            medicalData.patient.patientHeight = parsedData[0]?.patientHeight
            medicalData.patient.patientWeight = parsedData[0]?.patientWeight
            medicalData.patient.patientBirthYear = parsedData[0]?.patientBirthYear
            medicalData.patient.operatingUnitCode = parsedData[0]?.operatingUnitCode
            medicalData.patient.dataPermission = parsedData[0]?.dataPermission ? parsedData[0]?.dataPermission : medicalData.patient.dataPermission
          }
          else {
            toast.error(translate('simple.validation.dataIsNotValid'))
          }
        }
        else if (selectedValue == "stressquestion") {
          var stressquestion = []
          var headers = ['createdAt', 'painInRest', 'painInMovement', 'painInHardMovement', 'painWakesUp', 'usingPainkillers', 'painkillerDetails']
          var parsedData_key = Object.keys(parsedData[0])
          var isColumnMatches = true;
          parsedData_key.forEach((key) => {
            if (!headers.includes(key)) {
              isColumnMatches = false;
            }
          })

          if (isColumnMatches == true) {
            for (var i = 0; i < parsedData.length - 1; i++) {
              var stressquestions = {
                createdAt: parsedData[i].createdAt,
                painInRest: Number(parsedData[i].painInRest),
                painInMovement: Number(parsedData[i].painInMovement),
                painInHardMovement: Number(parsedData[i].painInHardMovement),
                painWakesUp: Number(parsedData[i].painWakesUp),
                usingPainkillers: parsedData[i].usingPainkillers,
                painkillerDetails: parsedData[i].painkillerDetails
              }
              stressquestion.push(stressquestions)
            }
            console.log("stress", stressquestion)
            if(medicalData.measurements){
              medicalData.measurements.stressquestions = stressquestion
            }
            else{
              medicalData.measurements = {
                stressquestions : stressquestion
              }
            }
            console.log(medicalData)
          }
          else {
            toast.error(translate('simple.validation.dataIsNotValid'))
          }
        }
        if (isColumnMatches == true) {
          await axios.patch(process.env.REACT_APP_API_URL + `/updatemedicaldata/${id}`, medicalData, CONFIG).then((res) => {
            console.log("status", res.status)
            if (res.status == 404) {
              toast.error(translate('simple.validation.dataNotUploaded'))
            }
            if (res.status == 204) {
              console.log("fds")
              toast.success(translate('simple.validation.operationSuccessful'));
            }
          })
        }
      })
    }
    else if (selectedValue == "defaultquestion") {
      await axios.get(process.env.REACT_APP_API_URL + `/questionnarie/${id}`, CONFIG).then(async (res) => {
        console.log("check", res.data.length)
        var headers = ['createdAt', 'questionnaireId', 'questionnaire_DefaultQuestionnaire_q1_title', 'questionnaire_DefaultQuestionnaire_q2_title', 'questionnaire_DefaultQuestionnaire_q3_title', 'questionnaire_DefaultQuestionnaire_q4_title']
        var parsedData_key = Object.keys(parsedData[0])
        var isColumnMatches = true;
        parsedData_key.forEach((key) => {
          if (!headers.includes(key)) {
            isColumnMatches = false;
          }
        })
        if (isColumnMatches == true) {
          if (res.data.length > 0) {
            var _id = res.data[0]._id;
            var patientId = res.data[0].patientId;
            var defaultquestionnaire = []
            for (var i = 0; i < parsedData.length - 1; i++) {
              var tempObj = {
                createdAt: parsedData[i].createdAt,
                questionnaireId: parsedData[i].questionnaireId,
                answers: {
                  q1: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q1_title),
                  q2: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q2_title),
                  q3: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q3_title),
                  q4: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q4_title)
                }
              }
              defaultquestionnaire.push(tempObj)
            }
            var data = {
              _id: _id,
              patientId: patientId,
              defaultquestionnaires: defaultquestionnaire
            }
            console.log("data", data)
            await axios.patch(process.env.REACT_APP_API_URL + `/questionnarie/${_id}`, data, CONFIG).then((res) => {

              if (res.status == 204) {
                toast.success(translate('simple.validation.operationSuccessful'));
              }
              else {
                toast.error(translate('simple.validation.dataNotUploaded'))
              }
            })
          }
          else {
            console.log("in else")
            var patientId = id;
            var defaultquestionnaire = []
            for (var i = 0; i < parsedData.length - 1; i++) {
              var tempObj = {
                createdAt: parsedData[i].createdAt,
                questionnaireId: parsedData[i].questionnaireId,
                answers: {
                  q1: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q1_title),
                  q2: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q2_title),
                  q3: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q3_title),
                  q4: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q4_title)
                }
              }
              defaultquestionnaire.push(tempObj)
            }
            var data = {
              patientId: patientId,
              defaultquestionnaires: defaultquestionnaire
            }
            console.log("data", data)
            axios.post(process.env.REACT_APP_API_URL + `/questionnarie`, data, CONFIG).then((res) => {
            })
          }
        }
        else {
          toast.error(translate('simple.validation.dataIsNotValid'))
        }

      })

    }

    //   var body = {
    //     "id": id,
    //     "patient": {
    //       'patientBirthYear':parsedData[0].patientBirthYear
    //     }
    //   }
    //   // Perform data handling logic here, e.g. update state, call API, etc.
    // await axios.patch(process.env.REACT_APP_API_URL + `/updatemedicaldata/${id}`, medicalData, CONFIG).then((res) => {
    // })
    // console.log('check',parsedData)
    // console.log("bdfb",parsedData[0].patientBirthYear);
  };

  const validateEmail = [required(), email('simple.validation.validEmail'), maxLength(100, 'simple.validation.maxLength100')];

  return (
    <Edit {...props} title='simple.editTitles.subscriptionPlan' undoable={false} transform={transform}>

      <SimpleForm>
        {(localStorage.getItem('roles')) == ('undefined') ?
          <MyFileUploader data='patient' onFileUpload={handleFileUpload} /> : null}
        <TextInput source='patient.email' label={translate('resources.transactionHistory.email')} validate={validateEmail} />
        <PasswordInput source='patient.pseudonym' disabled label={translate('resources.transactionHistory.resetPassword')} />
        <PreviewSubscriptionType source="subscriptionType" />
        {/* <SelectInput validate={required()} source='subscriptionType'
          label="Subscription type"
          choices={[{ id: 'free', name: 'Free trial, 30 days' },
          { id: 'monthly', name: 'Paid subscription, 1 month, 1.99$' }]}
        /> */}
        <NumberInput source='daysLeft' label={translate('resources.transactionHistory.daysLeftTrial')} />
        <DateInput validate={required()} source='createdAt' label={translate('resources.transactionHistory.createdAt')} />
        <DateInput validate={required()} source='subscriptionDate' label={translate('resources.transactionHistory.subscriptionStartDate')} />
        <DateInput validate={required()} source='subscriptionEndDate' label={translate('resources.transactionHistory.subscriptionEndDate')} />
        <RadioButtonGroupInput validate={required()} source='state' defaultValue={'active'} choices={[{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }]} />
      </SimpleForm>
    </Edit>

  );
};

export default TransactionHistoryEdit;