import * as React from 'react';
import { MenuItemLink, useTranslate,useRedirect  } from 'react-admin';
import BookIcon from '@material-ui/icons/Book';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import QuizIcon from '@mui/icons-material/Quiz';
import { authProvider } from './authProvider';
import decodeJwt from 'jwt-decode';
import SettingsIcon from '@mui/icons-material/Settings';
import TagIcon from '@mui/icons-material/Tag';
import HistoryIcon from '@mui/icons-material/History';
export const NewMenu = ({ onMenuClick, logout }) => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const { role, token } = JSON.parse(localStorage.getItem('auth')) || {};

    if (!role || role !== 'undefined') {
        // Logout if token has expired
        const decoded = decodeJwt(token);
        if ((decoded.exp < Date.now() / 1000 + 60 * 5) && decoded.exp !== undefined && decoded.exp !== null){
            authProvider.logout();
            alert('Please, sign-in again. Your session has been expired.');
            redirect('/login');
        }
            
    };

    return (
        <div>
            {(() => {
                if (role === 'doctor') {
                    return (
                        <div>
                            <MenuItemLink
                                to={`/`}
                                primaryText={translate('simple.module.organization')}
                                leftIcon={<BookIcon />}
                            />
                            <MenuItemLink to='/doctors?filter=%7B%7D' primaryText={translate('simple.module.doctors')} leftIcon={<SupervisorAccountIcon />} />
                            <MenuItemLink to='/patients?filter=%7B%7D' primaryText={translate('simple.module.patients')} leftIcon={<SupervisedUserCircleOutlinedIcon />} />
                            <MenuItemLink to='/FAQs?filter=%7B%7D' primaryText={translate('simple.module.FAQ')} onClick={onMenuClick} leftIcon={<QuizIcon />} />
                        </div>
                    )
                } else if (role === 'orgadmin') {
                    return (
                        <div>
                            <MenuItemLink
                                to={`/`}
                                primaryText={translate('simple.module.organization')}
                                leftIcon={<BookIcon />}
                            />
                            <MenuItemLink to='/orgadmins?filter=%7B%7D' primaryText={translate('simple.module.orgAdmins')} onClick={onMenuClick} leftIcon={<VerifiedUserIcon />} />
                            <MenuItemLink to='/doctors?filter=%7B%7D' primaryText={translate('simple.module.doctors')} leftIcon={<SupervisorAccountIcon />} />
                            <MenuItemLink to='/patients?filter=%7B%7D' primaryText={translate('simple.module.patients')} leftIcon={<SupervisedUserCircleOutlinedIcon />} />
                            {/* <MenuItemLink to='/achievedpatients?filter=%7B%7D' primaryText={translate('simple.module.Achievedpatients')} onClick={onMenuClick} leftIcon={<SettingsIcon />} /> */}
                            <MenuItemLink to='/FAQs?filter=%7B%7D' primaryText={translate('simple.module.FAQ')} onClick={onMenuClick} leftIcon={<QuizIcon />} />
                            {/* <MenuItemLink to='/AUDITLOGs?filter=%7B%7D' primaryText={translate('simple.module.AUDITLOG')} onClick={onMenuClick} leftIcon={<SettingsIcon />} />
                            <MenuItemLink to='/AchievedLogs?filter=%7B%7D' primaryText={translate('simple.module.AchievedLog')} onClick={onMenuClick} leftIcon={<SettingsIcon />} /> */}
                        </div>
                    )
                }
                else {
                    return (
                        <div>
                            <MenuItemLink
                                to={`/`}
                                primaryText={translate('simple.module.organization')}
                                leftIcon={<BookIcon />}
                            />
                            <MenuItemLink to='/customers?filter=%7B%7D' primaryText={translate('simple.module.customers')} onClick={onMenuClick} leftIcon={<AccountCircleSharpIcon />} />
                            <MenuItemLink to='/orgadmins?filter=%7B%7D' primaryText={translate('simple.module.orgAdmins')} onClick={onMenuClick} leftIcon={<VerifiedUserIcon />} />
                            <MenuItemLink to='/doctors?filter=%7B%7D' primaryText={translate('simple.module.doctors')} onClick={onMenuClick} leftIcon={<SupervisorAccountIcon />} />
                            <MenuItemLink to='/patients?filter=%7B%7D' primaryText={translate('simple.module.patients')} onClick={onMenuClick} leftIcon={<SupervisedUserCircleOutlinedIcon />} />
                           
                            <MenuItemLink to='/FAQs?filter=%7B%7D' primaryText={translate('simple.module.FAQ')} onClick={onMenuClick} leftIcon={<QuizIcon />} />
                            {/* <MenuItemLink to='/subscription-plan?filter=%7B%7D' primaryText={translate('simple.module.subscriptionPlan')} onClick={onMenuClick} leftIcon={<TagIcon />} /> */}
                            <MenuItemLink to='/lite-accounts?filter=%7B%7D' primaryText={translate('simple.module.transactionHistory')} onClick={onMenuClick} leftIcon={<HistoryIcon />} />
                            <MenuItemLink to='/achievedpatients?filter=%7B%7D' primaryText={translate('simple.module.Achievedpatients')} onClick={onMenuClick} leftIcon={<SettingsIcon />} />
                            <MenuItemLink to='/settings' primaryText={translate('simple.module.settingsNotifications')} onClick={onMenuClick} leftIcon={<SettingsIcon />} />
                            <MenuItemLink to='/AUDITLOGs?filter=%7B%7D' primaryText={translate('simple.module.AUDITLOG')} onClick={onMenuClick} leftIcon={<SettingsIcon />} />
                            <MenuItemLink to='/AchievedLogs?filter=%7B%7D' primaryText={translate('simple.module.AchievedLog')} onClick={onMenuClick} leftIcon={<SettingsIcon />} />
                        </div>
                    )
                }
            })()}
        </div>
    );
}
