const addUploadFeature = requestHandler => (type, resource, params) => {
    if ((type === 'UPDATE' || type === 'CREATE') && resource === 'customers') {
        if (params.data.logo) {
            const myFile = params.data.logo;

            if (type === 'CREATE' || (type === 'UPDATE' && (myFile.rawFile !== undefined )
            )) {
                if (!myFile.rawFile instanceof File) {
                    return Promise.reject('Error: Not a file...');
                }
                return Promise.resolve(convertFileToBase64(myFile))
                    .then((picture64) => ({
                        src: picture64,
                        title: `${myFile.rawFile.name}`
                    }))
                    .then(transformedMyFile => requestHandler(type, resource, {
                        ...params,
                        data: {
                            ...params.data,
                            logo: transformedMyFile
                        }
                    }));
            }


        }

    }
    return requestHandler(type, resource, params);
};

const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});
export default addUploadFeature;

