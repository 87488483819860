import React from 'react';
import {
  Edit,
  SimpleForm,
  SelectInput,
  TextInput,
  RadioButtonGroupInput,
  required,
  maxLength,
  DateTimeInput, Toolbar, SaveButton, DeleteButton
} from 'react-admin';

const FQAEdit = (props) => {
  const EditToolbar = props => {
    return <div className="deleteButton">
      <Toolbar {...props} >
        <SaveButton disabled={props.pristine} />
        <DeleteButton
          undoable={false}
          confirmTitle='simple.notify.confirmTitleFAQ'
          confirmContent='simple.notify.confirmContent'
        />
      </Toolbar>
    </div>
  };

  const validateQuestion = [required(), maxLength(100, 'simple.validation.maxLength100')];
  const validateAnswer = [required(), maxLength(500, 'simple.validation.maxLength500')];

  const transform = data => ({
    ...data,
    updated: new Date().toLocaleString(),
  })

  const choices = [
    { id: 'en', name: 'English' },
    { id: 'de', name: 'German' },
    { id: 'fi', name: 'Finish' },
];

  return (
    <Edit {...props} title='simple.editTitles.FAQ' undoable={false} transform={transform}>
      <SimpleForm toolbar={<EditToolbar />}>
      <SelectInput source="languagecode" choices={choices} label="Select Language"/>
        <TextInput source='question' validate={validateQuestion} disabled={(props.customerid === "") ? true : false} />
        <TextInput multiline source='answer' validate={validateAnswer} />
        <RadioButtonGroupInput source='state' choices={[{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }]} />
        <DateTimeInput disabled source='updated' initialValue={new Date().toLocaleString()} />
      </SimpleForm>
    </Edit>);
};

export default FQAEdit;