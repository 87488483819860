import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  useNotify,
  useRefresh, useTranslate, useQuery
} from 'react-admin';
import { CONFIG } from '../Constants';
const SettingsList = (props) => {
  const [setting, setCustomers] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();

  const { data: settings } = useQuery({
    type: 'get',
    resource: 'settings',
    payload: {
      pagination: { page: 1, perPage: 10 },

    },
  });

  const [user, setuser] = useState({
    activitydatahasnotbeenreceived: '',
    patientperformedthetestexercises: '',
    patientreportedpainmedication: '',
    patientansweredthequestionnaires: '',
    updated: "",
    activitydatahasnotbeenreceivedmsg: '',
    patientperformedthetestexercisesmsg: '',
    patientreportedpainmedicationmsg: '',
    patientansweredthequestionnairesmsg: '',
    patientreportedpainmedicationinterval: '',
    patientansweredthequestionnairesinterval: '',
    patientperformedthetestexercisesinterval: '',
    activitydatahasnotbeenreceivedinterval: '',
    version: "",
    reactadminversion:"",
    androidversion:"",
    iosversion:"",
    shoulderVidInstructionUrl: "",
    backVidInstructionUrl: "",
    wristVidInstructionUrl: "",
    kneeVidInstructionUrl: "",
    ankleVidInstructionUrl: "",
    // appSharing:""
  });

  useEffect(() => {
    if (settings && settings[0] !== undefined) {
      setCustomers(settings);
      loadUser();
    }
  }, [settings]);

  const loadUser = async (e) => {
    await axios.get(process.env.REACT_APP_API_URL + `/settings/` + settings[0].id, CONFIG).then((res) => {
      setuser(res.data)
      console.log(res.data)
      setIsChecked(res.data.appSharing)
    }).catch((error) => {
    })
  }

  const { activitydatahasnotbeenreceived, patientperformedthetestexercises, patientreportedpainmedication, patientansweredthequestionnaires,
    activitydatahasnotbeenreceivedmsg, patientperformedthetestexercisesmsg, patientreportedpainmedicationmsg, patientansweredthequestionnairesmsg,
    patientreportedpainmedicationinterval, patientansweredthequestionnairesinterval, patientperformedthetestexercisesinterval, activitydatahasnotbeenreceivedinterval,androidversion,iosversion,reactadminversion,version, shoulderVidInstructionUrl, backVidInstructionUrl, wristVidInstructionUrl, kneeVidInstructionUrl, ankleVidInstructionUrl, /*appSharing */ } = user;

  const onInputChange = e => {
    setuser({ ...user, [e.target.name]: e.target.value })
  }

  const onCheckBoxChange = e => {
    setIsChecked(e.target.checked);
    // 👇️ this is the checked value of the field
    console.log(e.target.checked);
  }

  const onSubmit = async e => {
    e.preventDefault();
    user.updated = new Date().toJSON();
    user.appSharing = isChecked
    if (settings && settings[0] !== undefined) {
      await axios.put(process.env.REACT_APP_API_URL + `/settings/` + settings[0].id, user, CONFIG).then((res) => { })
    } else {
      await axios.post(process.env.REACT_APP_API_URL + `/settings`, user, CONFIG).then((res) => { })
    }
    notify('simple.notify.save_settings');
    refresh();
  }

  return (
    <div className='notificationSettingsMain'>
      <form className='form-group col-lg-12' onSubmit={onSubmit}>
        <div className='card-header'>
          <h3>{translate('resources.notificationSettings.name')}</h3>
        </div>
        <div className='card-body'>
          {/* <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.version')}<label className="text-danger">*</label></label>
            <div className="col-sm-4">
              <input required name='version' maxLength="10" onChange={e => onInputChange(e)} className="msg-width"
                id="version" value={version} />
            </div>
          </div> */}
            <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.reactadminversion')}</label>
            <div className="col-sm-4">
              <input  name='reactadminversion' maxLength="10" onChange={e => onInputChange(e)} className="msg-width"
                id="reactadminversion" value={reactadminversion} />
            </div>
          </div>


          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.androidversion')}</label>
            <div className="col-sm-4">
              <input  name='androidversion' maxLength="10" onChange={e => onInputChange(e)} className="msg-width"
                id="androidversion" value={androidversion} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.iosversion')}</label>
            <div className="col-sm-4">
              <input  name='iosversion' maxLength="10" onChange={e => onInputChange(e)} className="msg-width"
                id="iosversion" value={iosversion} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.activitydatahasnotbeenreceived')}</label>
            <div className="col-sm-3">
              <input type="number" min={1}  name='activitydatahasnotbeenreceived' onChange={e => onInputChange(e)}
                className="msg-width"
                id="activitydatahasnotbeenreceived" value={activitydatahasnotbeenreceived} />
            </div>
            <label className="col-sm-3 col-form-label ml-5">{translate('resources.notificationSettings.fields.activitydatahasnotbeenreceivedinterval')}</label>
            <div className="col-sm-3">
              <input type="number" min={1} name='activitydatahasnotbeenreceivedinterval' onChange={e => onInputChange(e)}
                className="msg-width"
                id="activitydatahasnotbeenreceivedinterval" value={activitydatahasnotbeenreceivedinterval} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.activitydatahasnotbeenreceivedmsg')}</label>
            <div className="col-sm-4">
              <input type="text"  maxLength="100" title={activitydatahasnotbeenreceivedmsg} name='activitydatahasnotbeenreceivedmsg' onChange={e => onInputChange(e)} className="message-width"
                id="activitydatahasnotbeenreceivedmsg" value={activitydatahasnotbeenreceivedmsg} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.patientreportedpainmedication')}</label>
            <div className="col-sm-3">
              <input type="number" min={1}  name='patientreportedpainmedication' onChange={e => onInputChange(e)} className="msg-width" id="patientreportedpainmedication"
                value={patientreportedpainmedication} />
            </div>
            <label className="col-sm-3 col-form-label ml-5">{translate('resources.notificationSettings.fields.patientreportedpainmedicationinterval')}</label>
            <div className="col-sm-3">
              <input type="number" min={1} name='patientreportedpainmedicationinterval' onChange={e => onInputChange(e)} className="msg-width"
                id="patientreportedpainmedicationinterval" value={patientreportedpainmedicationinterval} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.patientreportedpainmedicationmsg')}</label>
            <div className="col-sm-4">
              <input type="text"  maxLength="100" name='patientreportedpainmedicationmsg' onChange={e => onInputChange(e)} className="message-width"
                id="patientreportedpainmedicationmsg" title={patientreportedpainmedicationmsg} value={patientreportedpainmedicationmsg} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.patientansweredthequestionnaires')}</label>
            <div className="col-sm-4">
              <input type="number" min={1}  name='patientansweredthequestionnaires' onChange={e => onInputChange(e)} className="msg-width"
                value={patientansweredthequestionnaires} id="patientansweredthequestionnaires" />
            </div>
            <label className="col-sm-3 col-form-label ml-5">{translate('resources.notificationSettings.fields.patientansweredthequestionnairesinterval')}</label>
            <div className="col-sm-4">
              <input type="number" min={1} name='patientansweredthequestionnairesinterval' onChange={e => onInputChange(e)} className="msg-width"
                id="patientansweredthequestionnairesinterval" value={patientansweredthequestionnairesinterval} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.patientansweredthequestionnairesmsg')}</label>
            <div className="col-sm-4">
              <input type="text"  maxLength="100" title={patientansweredthequestionnairesmsg} name='patientansweredthequestionnairesmsg' onChange={e => onInputChange(e)} className="message-width"
                id="patientansweredthequestionnairesmsg" value={patientansweredthequestionnairesmsg} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.patientperformedthetestexercises')}</label>
            <div className="col-sm-3">
              <input type="number" min={1}  name='patientperformedthetestexercises' onChange={e => onInputChange(e)}
                className="msg-width" value={patientperformedthetestexercises} id="patientperformedthetestexercises" />
            </div>
            <label className="col-sm-3 col-form-label ml-5">{translate('resources.notificationSettings.fields.patientperformedthetestexercisesinterval')}</label>
            <div className="col-sm-3">
              <input type="number" min={1} name='patientperformedthetestexercisesinterval' onChange={e => onInputChange(e)} className="msg-width"
                id="patientperformedthetestexercisesinterval" value={patientperformedthetestexercisesinterval} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.patientperformedthetestexercisesmsg')}</label>
            <div className="col-sm-4">
              <input type="text"  maxLength="100" title={patientperformedthetestexercisesmsg} name='patientperformedthetestexercisesmsg' onChange={e => onInputChange(e)} className="message-width"
                id="patientperformedthetestexercisesmsg" value={patientperformedthetestexercisesmsg} />
            </div>
          </div>

          {/* Sprint 13 changes */}
          {/* <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.shoulderVidInstructionUrl')}<label className="text-danger">*</label></label>
            <div className="col-sm-4">
              <input type="text" required maxLength="1000" title={shoulderVidInstructionUrl} name='shoulderVidInstructionUrl' onChange={e => onInputChange(e)} className="message-width"
                id="shoulderVidInstructionUrl" value={shoulderVidInstructionUrl} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.backVidInstructionUrl')}<label className="text-danger">*</label></label>
            <div className="col-sm-4">
              <input type="text" required maxLength="1000" title={backVidInstructionUrl} name='backVidInstructionUrl' onChange={e => onInputChange(e)} className="message-width"
                id="backVidInstructionUrl" value={backVidInstructionUrl} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.wristVidInstructionUrl')}<label className="text-danger">*</label></label>
            <div className="col-sm-4">
              <input type="text" required maxLength="1000" title={wristVidInstructionUrl} name='wristVidInstructionUrl' onChange={e => onInputChange(e)} className="message-width"
                id="wristVidInstructionUrl" value={wristVidInstructionUrl} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.kneeVidInstructionUrl')}<label className="text-danger">*</label></label>
            <div className="col-sm-4">
              <input type="text" required maxLength="1000" title={kneeVidInstructionUrl} name='kneeVidInstructionUrl' onChange={e => onInputChange(e)} className="message-width"
                id="kneeVidInstructionUrl" value={kneeVidInstructionUrl} />
            </div>
          </div>

          <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.ankleVidInstructionUrl')}</label>
            <div className="col-sm-4">
              <input type="text" maxLength="1000" title={ankleVidInstructionUrl} name='ankleVidInstructionUrl' onChange={e => onInputChange(e)} className="message-width"
                id="ankleVidInstructionUrl" value={ankleVidInstructionUrl} />
            </div>
          </div> */}

          {/* Done Sprint 13 changes */}

          <div className="mb-3 row">
            {/* <label className="col-sm-3 col-form-label">{translate('resources.notificationSettings.fields.apSharing')}<label className="text-danger">*</label></label>
            <div className="col-sm-4">
              <input type="text" required maxLength="100" title={kneeVidInstructionUrl} name='kneeVidInstructionUrl' onChange={e => onInputChange(e)} className="message-width"
                id="kneeVidInstructionUrl" value={kneeVidInstructionUrl} />
            </div> */}

{/* sprint 13 changes */}
            {/* <div className="form-check">
              <input className="form-check-input" type="checkbox" checked={isChecked} id="flexCheckChecked" name ="appSharing" onChange={e=>onCheckBoxChange(e)}/>
                <label className="form-check-label" >
                {translate('resources.notificationSettings.fields.apSharing')}
                </label>
            </div> */}

{/* to  */}
            {/* <input
        type="checkbox"
        id="checkbox-id"
        name="checkbox-name"
        onChange={e=>onInputChange(e)}
        
      /> */}
            {/* <label class="container">One
  <input type="checkbox" checked="checked" />
  <span class="checkmark"></span>
</label> */}
          </div>
        </div>

        <button type="submit" style={{ marginTop: '15px' }} className="MuiButtonBase-root MuiButton-root MuiButton-contained RaSaveButton-button-124 MuiButton-containedPrimary">
          <span className="MuiButton-label">
            <svg className="MuiSvgIcon-root RaSaveButton-leftIcon-125 RaSaveButton-icon-126" style={{ marginRight: '7px' }} focusable="false" viewBox="0 0 24 24"
              aria-hidden="true"><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"></path></svg>{translate('ra.action.save')}</span></button>
      </form>
    </div>
  );
};


export default SettingsList


