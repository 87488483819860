import React, { useState, useEffect, } from 'react';
import {
  Edit,
  SimpleForm,
  SelectInput,
  useQuery,
  TextInput,
  RadioButtonGroupInput,
  required,
  maxLength, regex, Labeled,
  email, Toolbar, SaveButton, DeleteButton,BooleanInput,Button,useTranslate
} from 'react-admin';
import Constants from '../Constants';
import axios from 'axios';
import { CONFIG } from './../Constants';
import Papa from 'papaparse';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const convertMidnightToMidnightInGMT0ForTheSameDay = date => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};



const PatientEdit = (props) => {
  const translate = useTranslate();
  const [selectedValue, setSelectedValue] = useState('walking');
  const [fileParsedData, setParsedData] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  const [doctors, setDoctors] = useState([]);
  const [customerCode, setCustomerCode] = useState("");
  const [checkEmail, setEmail] = useState("");
  const [error, setError] = useState(null)
  const [checkPatientEmail, setPatientEmail] = useState("");
  const [treatmentTargetSide, setTreatmentTargetSide] = useState(false);
  const [treatmentTarget, setTreatmentTarget] = useState(props.record?.patient?.treatmentTarget || '');
  const [showTreatmentTargetSide, setShowTreatmentTargetSide] = useState(treatmentTarget !== 'back');
  const [selectedTreatmentTarget, setSelectedTreatmentTarget] = useState('');
  const { data: doctor } = useQuery({
    type: 'getList',
    resource: 'doctors',
    payload: {
      sort: { field: 'name', order: 'ASC' },
      filter: JSON.parse(localStorage.getItem('auth')).role ? (JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' ?
        {
          state: Constants.ACTIVE,
          customerid: JSON.parse(localStorage.getItem('auth'))?.customerid
        } : {
          state: Constants.ACTIVE,
          id: JSON.parse(localStorage.getItem('auth'))?.doctorid
        }) : {
        state: Constants.ACTIVE,
      },
    },
  });

  useEffect(async() => {
    // Update the visibility of treatmentTargetSide whenever treatmentTarget changes
    await axios.get(process.env.REACT_APP_API_URL + `/patients/${props.id}`, CONFIG).then((res)=>{
      setShowTreatmentTargetSide(res.data.patient.treatmentTarget!=='back')
    })
  }, [treatmentTarget]);

  const onTreatmentTargetValueChange = (value) => {
    // Update the treatmentTarget state when the value changes
    // if(value.target.value !== 'back'){
    //   setShowTreatmentTargetSide(true)
    // }
    // else setShowTreatmentTargetSide(false)
    setSelectedTreatmentTarget(value.target.value);
  };

  useEffect(() => {
    if (doctor) {
      setDoctors(doctor.map((d) => ({ id: d.id, name: d.name })));
      if (doctor.length === 1) {
        setCustomerCode(doctor[0].customercode)
      }
    }
  }, [doctor]);

  const onDoctorChange = (e) => {
    const result = doctor.filter(id => id.id === e.target.value)
    setCustomerCode(result[0].customercode)
    setEmail(result[0].email)
    if (checkPatientEmail !== "" && checkEmail !== "") {
      if (checkPatientEmail === result[0].email) {
        setError("doctor and patient cannot be same")
      }
      else {
        setError(null)
      }
    }
  }

  const onEmailChange = (e) => {
    setPatientEmail(e)
    if (checkPatientEmail !== "" && checkEmail !== "") {
      if (e === checkEmail) {
        setError("doctor and patient cannot be same")
      }
      else {
        setError(null)
      }
    }
  }


  const EditToolbar = props => {
    return <div className="deleteButton">
      <Toolbar {...props} >
        <SaveButton disabled={props.pristine} />
        <DeleteButton
          undoable={false}
          confirmTitle='simple.notify.confirmTitle'
          confirmContent='simple.notify.confirmContent'
        />
      </Toolbar>
    </div>
  };

  const transform = (data) => ({
    ...data,
    patient: {
      pseudonym: data.patient.pseudonym,
      state: data.patient.state,
      name: data.patient.name,
      phonenumber: data.patient.phonenumber,
      email: data.patient.email,
      doctor: data.patient.doctor,
      extradata: data.patient.extradata,
      gender: data.patient.gender,
      operatingUnitCode: data.patient.operatingUnitCode,
      operationDate: data.patient.operationDate,
      patientBirthYear: data.patient.patientBirthYear,
      patientHeight: data.patient.patientHeight,
      patientWeight: data.patient.patientWeight,
      previousOperations: data.patient.previousOperations,
      treatmentTarget: data.patient.treatmentTarget,
      position: data.patient.position,
      updated: new Date().toLocaleString(),
    },
  });

  const validateDoctor = [required()];
  const validateName = [maxLength(50, 'simple.validation.maxLength50')];
  const validateEmail = [onEmailChange, email('simple.validation.validEmail'), maxLength(100, 'simple.validation.maxLength100')];
  const validatePhoneNumber = [ maxLength(20, 'simple.validation.maxLength20'),
  regex(/^[0-9]+$/, 'simple.validation.onlyNumbers')];

  const choices = [
    { id: 'knee', name: 'Knee-KOOS' },
    { id: 'back', name: 'Back-Oswestry' },
    { id: 'shoulder', name: 'Shoulder-DASH' },
    { id: 'wrist', name: 'Wrist-DASH' },
    { id: 'oks', name: 'Knee-OKS' },
    {id:'ankle',name:'Ankle-OMA'}
];

  const treatmentTargetSideChoices = [
    {id:'none',name:'None'},
    {id: 'left', name: 'Left'},
    {id: 'right', name: 'Right'},
    {id:'both',name:'Both'}
  ];

  const filteredTreatmentTargetSideChoices = selectedTreatmentTarget === 'back' ? [{ id: 'none', name: 'None' }] : treatmentTargetSideChoices;

  const MyFileUploader = ({ onFileUpload, data }) => {

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      console.log("cd")
      setParsedData(null); // Reset fileParsedData
      setSelectedFileName(file ? file.name : null);
      // Read the file content as text
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target.result;

        // Parse the CSV data using PapaParse
        const parsedData = Papa.parse(csvData, { header: true });

        // Call the onFileUpload callback with the parsed data
        // onFileUpload(parsedData.data);
        setParsedData(parsedData.data)
      };
      reader.readAsText(file);
    };

    return (
      <div style={{ marginBottom: '25px', marginTop: '15px' }}>
        <h2>Import Data</h2>
        <div>
          <select value={selectedValue} onChange={handleDropdownChange} style={{ marginBottom: '35px' }}>
            <option value="walking">Walking Activity</option>
            <option value="patientInfo">Patient Basic Info</option>
            <option value="stressquestion">Stress Question</option>
            <option value="defaultquestion">Default Question</option>
          </select>
        </div>
        <input type="file" accept=".csv" onChange={handleFileChange} id="fileInput" style={{ display: 'none' }} />
        <label htmlFor="fileInput" style={{
          cursor: 'pointer',
          backgroundColor: '#CECED1',
          color: 'white',
          padding: '10px 15px',
          borderRadius: '5px',
          border: 'none',
          fontWeight: 'bold',
          marginTop: '25px'
        }}>
          Choose a file
        </label>
        <p>
          {selectedFileName ? (
            `Selected File: ${selectedFileName}`
          ) : (
            'No file chosen'
          )}
        </p>
        <Button label='submit' onClick={() => { handleFileUpload(fileParsedData) }} style={{ backgroundColor: '#CECED1' }}></Button>
        <div style={{ marginTop: '15px' }}>
          <ToastContainer style={{ marginTop: '30px' }} />
        </div>
        <hr></hr>
      </div>
    );
  };


  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };


  const handleFileUpload = async (parsedData) => {
    const currentURL = window.location.href;
    var startIndex = currentURL.indexOf("patients/") + 9;
    var id = currentURL.substring(startIndex);
    
    var medicalData;
    console.log("parse Data", parsedData)

    if (selectedValue !== "defaultquestion") {
      await axios.get(process.env.REACT_APP_API_URL + `/patients/${id}`, CONFIG).then(async (res) => {

        medicalData = res.data;
        if (selectedValue === 'walking') {
          var headers = ['date', 'dailyTotalStepQuantity', 'hourlyMaxStepsForDay', 'maxOneMinuteSteps', 'maxSixMinuteSteps', 'oneMinuteStepsAvg', 'sixMinutesStepsAvg']
          var new_data = []

          var parsedData_key = Object.keys(parsedData[0])
          var isColumnMatches = true;
          parsedData_key.forEach((key) => {
            if (!headers.includes(key)) {
              isColumnMatches = false;
            }
          })
          // for (const key in parsedData_key){
          //   console.log(key)
          //   if(headers.includes(key)){
          //     console.log("check",key)
          //   }
          //   else {
          //     console.log(`${key} is not in the array!`);
          //   }
          // }
          // const result = headers.reduce((acc, curr) => {
          //   acc[curr] = obj[curr];
          //   return acc;
          // }, {});

          if (isColumnMatches === true) {
            for (var i = 0; i < parsedData.length - 1; i++) {
              var tempObj = {
                _id: convertMidnightToMidnightInGMT0ForTheSameDay(new Date(parsedData[i].date)).getTime(),
                date: parsedData[i].date,
                walkingData: {
                  dailyTotalStepQuantity: Number(parsedData[i].dailyTotalStepQuantity),
                  hourlyMaxStepsForDay: Number(parsedData[i].hourlyMaxStepsForDay),
                  maxOneMinuteSteps: Number(parsedData[i].maxOneMinuteSteps),
                  maxSixMinuteSteps: Number(parsedData[i].maxSixMinuteSteps),
                  oneMinuteStepsAvg: Number(parsedData[i]?.oneMinuteStepsAvg ? parsedData[i]?.oneMinuteStepsAvg : "0"),
                  sixMinutesStepsAvg: Number(parsedData[i]?.sixMinutesStepsAvg ? parsedData[i]?.sixMinutesStepsAvg : "0")
                }
              }
              new_data.push(tempObj)
            }
            if(medicalData.measurements){
              medicalData.measurements.walkingactivity = new_data
            }else{
              medicalData.measurements = {
                walkingactivity : new_data
              }
            }
            
          }
          else {
            toast.error(translate('simple.validation.dataIsNotValid'))
          }
        }
        else if (selectedValue === "patientInfo") {
          var headers = ['id','previousOperations','previousOperations_1','treatmentTarget', 'operationDate', 'gender', 'patientHeight', 'patientWeight', 'patientBirthYear', 'operatingUnitCode', 'dataPermission']
          var parsedData_key = Object.keys(parsedData[0])
          var isColumnMatches = true;
          parsedData_key.forEach((key) => {
            if (!headers.includes(key)) {
              isColumnMatches = false;
            }
          })
          if (isColumnMatches === true) {
            medicalData.patient.treatmentTarget = parsedData[0]?.treatmentTarget
            medicalData.patient.operationDate = parsedData[0]?.operationDate
            medicalData.patient.gender = parsedData[0]?.gender
            medicalData.patient.patientHeight = parsedData[0]?.patientHeight
            medicalData.patient.patientWeight = parsedData[0]?.patientWeight
            medicalData.patient.patientBirthYear = parsedData[0]?.patientBirthYear
            medicalData.patient.operatingUnitCode = parsedData[0]?.operatingUnitCode
            medicalData.patient.dataPermission = parsedData[0]?.dataPermission ? parsedData[0]?.dataPermission : medicalData.patient.dataPermission
          }
          else {
            toast.error(translate('simple.validation.dataIsNotValid'))
          }
        }
        else if (selectedValue === "stressquestion") {
          var stressquestion = []
          var headers = ['createdAt', 'painInRest', 'painInMovement', 'painInHardMovement', 'painWakesUp', 'usingPainkillers', 'painkillerDetails']
          var parsedData_key = Object.keys(parsedData[0])
          var isColumnMatches = true;
          parsedData_key.forEach((key) => {
            if (!headers.includes(key)) {
              isColumnMatches = false;
            }
          })

          if (isColumnMatches === true) {
            for (var i = 0; i < parsedData.length - 1; i++) {
              var stressquestions = {
                createdAt: parsedData[i].createdAt,
                painInRest: Number(parsedData[i].painInRest),
                painInMovement: Number(parsedData[i].painInMovement),
                painInHardMovement: Number(parsedData[i].painInHardMovement),
                painWakesUp: Number(parsedData[i].painWakesUp),
                usingPainkillers: parsedData[i].usingPainkillers,
                painkillerDetails: parsedData[i].painkillerDetails
              }
              stressquestion.push(stressquestions)
            }
            console.log("stress", stressquestion)
            if(medicalData.measurements){
              medicalData.measurements.stressquestions = stressquestion
            }
            else{
              medicalData.measurements = {
                stressquestions : stressquestion
              }
            }
          }
          else {
            toast.error(translate('simple.validation.dataIsNotValid'))
          }
        }
        if (isColumnMatches === true) {
          await axios.patch(process.env.REACT_APP_API_URL + `/updatemedicaldata/${id}`, medicalData, CONFIG).then((res) => {
            if (res.status === 404) {
              toast.error(translate('simple.validation.dataNotUploaded'))
            }
            if (res.status === 204) {
              toast.success(translate('simple.validation.operationSuccessful'));
            }
          })
        }
      })
    }
    else if (selectedValue === "defaultquestion") {
      await axios.get(process.env.REACT_APP_API_URL + `/questionnarie/${id}`, CONFIG).then(async (res) => {
        console.log("check", res.data.length)
        var headers = ['createdAt', 'questionnaireId', 'questionnaire_DefaultQuestionnaire_q1_title', 'questionnaire_DefaultQuestionnaire_q2_title', 'questionnaire_DefaultQuestionnaire_q3_title', 'questionnaire_DefaultQuestionnaire_q4_title']
        var parsedData_key = Object.keys(parsedData[0])
        var isColumnMatches = true;
        parsedData_key.forEach((key) => {
          if (!headers.includes(key)) {
            isColumnMatches = false;
          }
        })
        if (isColumnMatches === true) {
          if (res.data.length > 0) {
            var _id = res.data[0]._id;
            var patientId = res.data[0].patientId;
            var defaultquestionnaire = []
            for (var i = 0; i < parsedData.length - 1; i++) {
              var tempObj = {
                createdAt: parsedData[i].createdAt,
                questionnaireId: parsedData[i].questionnaireId,
                answers: {
                  q1: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q1_title),
                  q2: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q2_title),
                  q3: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q3_title),
                  q4: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q4_title)
                }
              }
              defaultquestionnaire.push(tempObj)
            }
            var data = {
              _id: _id,
              patientId: patientId,
              defaultquestionnaires: defaultquestionnaire
            }
            console.log("data", data)
            await axios.patch(process.env.REACT_APP_API_URL + `/questionnarie/${_id}`, data, CONFIG).then((res) => {

              if (res.status === 204) {
                toast.success(translate('simple.validation.operationSuccessful'));
              }
              else {
                toast.error(translate('simple.validation.dataNotUploaded'))
              }
            })
          }
          else {
            console.log("in else")
            var patientId = id;
            var defaultquestionnaire = []
            for (var i = 0; i < parsedData.length - 1; i++) {
              var tempObj = {
                createdAt: parsedData[i].createdAt,
                questionnaireId: parsedData[i].questionnaireId,
                answers: {
                  q1: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q1_title),
                  q2: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q2_title),
                  q3: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q3_title),
                  q4: Number(parsedData[i].questionnaire_DefaultQuestionnaire_q4_title)
                }
              }
              defaultquestionnaire.push(tempObj)
            }
            var data = {
              patientId: patientId,
              defaultquestionnaires: defaultquestionnaire
            }
            console.log("data", data)
            axios.post(process.env.REACT_APP_API_URL + `/questionnarie`, data, CONFIG).then((res) => {
            })
          }
        }
        else {
          toast.error(translate('simple.validation.dataIsNotValid'))
        }

      })

    }
  };

  const month_number = [
    {id:30,name:'1 month'},
    {id:60,name:'2 months'},
    {id:90,name:'3 months'},
    {id:120,name:'4 months'},
    {id:150,name:'5 months'},
    {id:180,name:'6 months'},
  ]
  const convertMonthtoDays = (e) => {
    const daysPerMonth = 30; // Assuming each month has 30 days for simplicity
    const totalDays = e.target.value * daysPerMonth;
    return totalDays;
};

  const defaultMonthValue = 30;


  return (
    <Edit {...props} title='simple.editTitles.patient' undoable={false} transform={transform}>
      <SimpleForm toolbar={<EditToolbar />}>
      {(localStorage.getItem('roles')) == ('undefined') ?
          <MyFileUploader data='patient' onFileUpload={handleFileUpload} /> : null}
      <SelectInput source='doctorid' choices={doctors} onChange={onDoctorChange} validate={validateDoctor} />
      <SelectInput id='treatmentTarget' source="patient.treatmentTarget" choices={choices} label="Select Treatment Target" onChange={onTreatmentTargetValueChange} />
        <SelectInput
          source='patient.position'
          choices={filteredTreatmentTargetSideChoices}
          label="Select Treatment Target Side"
        />
        <TextInput disabled source='patient.pseudonym' />
        <TextInput source='patient.name' validate={validateName} />
        <TextInput source='patient.phonenumber' validate={validatePhoneNumber} />
        <BooleanInput source="isresearchuser" />
        <SelectInput
          source="DaysFromLastfetch"
          choices={month_number}
          label="Months of data needed"
          onChange={convertMonthtoDays}
          defaultValue={defaultMonthValue}
        />
        <TextInput source='patient.email' validate={validateEmail} onChange={onEmailChange} />
        {error ? <Labeled label={error} className="errorMessage" /> : null}
        <RadioButtonGroupInput source='patient.state' choices={[{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }]} />
        <TextInput disabled source='patient.os' label = 'Operating System'/>
        <TextInput disabled source='patient.osVersion' label='OS version'/>
        <TextInput disabled source='patient.version' label='App Version'/>
        
        
        <TextInput disabled source='patient.updated' initialValue={new Date().toLocaleString()} />
      </SimpleForm>
    </Edit>
  );
};

export default PatientEdit;