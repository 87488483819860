import React from 'react';
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  RadioButtonGroupInput,
  useNotify,
  useRefresh,
  useRedirect,
  required,
  maxLength,
  useTranslate,
} from 'react-admin';

const FQACreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();
  const onSuccess = async ({ data }) => {
    notify('simple.notify.save_FAQ');
    redirect(`/FAQs?filter=%7B"id"%3A"${data.id}"%7D`);
    refresh();
  };

  const createdDateTime = new Date().toLocaleString();
  const DefValues = () => ({
    state: 'active',
    updated: createdDateTime,
    customerid: JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' ? JSON.parse(localStorage.getItem('auth'))?.customerid : ""
  });

  const validateQuestion = [required(), maxLength(100, 'simple.validation.maxLength100')];
  const validateAnswer = [required(), maxLength(500, 'simple.validation.maxLength500')];
  const choices = [
    { id: 'en', name: translate('resources.FAQs.fields.english') },
    { id: 'de', name: translate('resources.FAQs.fields.german') },
    { id: 'fi', name: translate('resources.FAQs.fields.finnish') },
];

  return (
    <Create {...props} title='simple.createTitles.FAQ' onSuccess={onSuccess}>
      <SimpleForm initialValues={DefValues}>
      <SelectInput source="languagecode" choices={choices} label="Select Language"/>
        <TextInput source='question' validate={validateQuestion} />
        <TextInput multiline source='answer' validate={validateAnswer} />
        <RadioButtonGroupInput source='state' choices={[{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }]} />
        <TextInput disabled source='updated' initialValue={new Date().toLocaleString()} />
      </SimpleForm>
    </Create>
  );
};

export default FQACreate;