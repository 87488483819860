import React, { useState } from 'react';
import {
  List, Filter, Datagrid, TextField, TopToolbar, NumberField, useNotify,
  EmailField, SearchInput, FunctionField, BulkDeleteButton, useTranslate,ExportButton,DeleteButton
} from 'react-admin';
import Button from '@material-ui/core/Button';
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import redDown from "./images/red-down-arrow.png";
import greenUp from "./images/Green-Arrow-UP.png";
import grayQuestionMark from "./images/grey-question-mark.jpg";
import orangeRight from "./images/orange-right-arrow.png";
import orangeRound from "./images/orange-round.png";
import redRound from "./images/red-round.png";
import greenRound from "./images/green-round.png";
import { DASHBOARDLINK, CONFIG } from './../Constants';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Popup from "reactjs-popup";
import axios from 'axios';


// ------------- filter component -------------
const AchievedMedicalDataFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchInput placeholder={translate('simple.search.patient')} source='patient.pseudonym' resettable alwaysOn />
    </Filter>
  );
}



const MyBulkActionButtons = props => {
  if (JSON.parse(localStorage.getItem('auth')).role === 'doctor') {
    return false
  } else {
    return <BulkDeleteButton
      undoable={false}
      confirmTitle='simple.notify.confirmTitle'
      confirmContent='simple.notify.confirmContent'
      {...props}
    />
  }
}

const MyActions = props => {
  if (JSON.parse(localStorage.getItem('auth')).role === 'doctor') {
    return <TopToolbar>
      <ExportButton />
    </TopToolbar>
  } else {
    return <TopToolbar>
      <ExportButton />
      <DeleteButton
        undoable={false}
        confirmTitle='simple.notify.confirmTitleAchievedLog'
        confirmContent='simple.notify.confirmContent'
      />
    </TopToolbar>
  }

}

const postRowClick = (id, resource, record) => {
  return (record.customerid === "" && (JSON.parse(localStorage.getItem('auth')).role === 'orgadmin'
    || JSON.parse(localStorage.getItem('auth')).role === 'doctor'))
    ? undefined : 'edit';
}





const AchievedMedicalData = (props) => {
    const useStyles = makeStyles({
        root: {
          maxWidth: "100%",
          position: "top",
          height: "100%",
        },
        media: {
          height: "14px",
          width: "14px",
          objectFit: "contain"
        }
      });
      
    const classes = useStyles();
    const translate = useTranslate();

    const PreviewActivityLevel = ({ record }) => {
        if (record.patient.activitylevel === "Red") {
          return <CardMedia
            component="img"
            className={classes.media}
            src={redRound}
            title={translate('simple.listTitles.tooltips.ACLevelRedRound')}
          />
        }
        else if (record.patient.activitylevel === "Green") {
          return <CardMedia
            component="img"
            className={classes.media}
            src={greenRound}
            title={translate('simple.listTitles.tooltips.ACLevelGreenRound')}
          />
        } else if (record.patient.activitylevel === "Yellow") {
          return <CardMedia
            component="img"
            className={classes.media}
            src={orangeRound}
            title={translate('simple.listTitles.tooltips.ACLevelYellowRound')}
          />
        } else {
          return <CardMedia
            component="img"
            className={classes.media}
            src={grayQuestionMark}
            title={translate('simple.listTitles.tooltips.ACLevelNoActivity')}
          />
        }
    
      }

      const PreviewTrend = ({ record }) => {
        if (record.patient.trend === "Red") {
          return <CardMedia
            component="img"
            className={classes.media}
            src={redDown}
            title={translate('simple.listTitles.tooltips.trendRedArrow')}
          />
        } else if (record.patient.trend === "Green") {
          return <CardMedia
            component="img"
            className={classes.media}
            src={greenUp}
            title={translate('simple.listTitles.tooltips.trendGreenArrow')}
          />
        } else if (record.patient.trend === "Yellow") {
          return <CardMedia
            component="img"
            className={classes.media}
            src={orangeRight}
            title={translate('simple.listTitles.tooltips.trendyellowArrow')}
          />
        } else {
          return <CardMedia
            component="img"
            className={classes.media}
            src={grayQuestionMark}
            title={translate('simple.listTitles.tooltips.noTrendActivity')}
          />
        }
    
      }
  return (
    <List {...props} title='simple.listTitles.Achievedpatients' actions={<MyActions />} bulkActionButtons={MyBulkActionButtons(props)}
      filters={<AchievedMedicalDataFilter />}
    //   filter={
    //     {
    //       customerid: JSON.parse(localStorage.getItem('auth'))?.customerid ? JSON.parse(localStorage.getItem('auth'))?.customerid : ""
    //     }
    //   }
      sort={{ field: 'updated', order: 'DESC' }}>
 <Datagrid rowClick='edit' >
        <TextField source='patient.pseudonym' label={translate('resources.transactionHistory.pseudonym')} />
        <TextField source='createdAt' label={translate('resources.transactionHistory.createdAt')}/>
        <TextField source='subscriptionType' label={translate('resources.transactionHistory.subscriptionType')} />
        <TextField source='daysLeft' label={translate('resources.transactionHistory.daysLeft')} />
        <TextField source='subscriptionDate' label={translate('resources.transactionHistory.subscriptionStartDate')} />
        <TextField source='subscriptionEndDate' label={translate('resources.transactionHistory.subscriptionEndDate')} />
        <TextField source='subscriptionstatus' label={translate('resources.transactionHistory.subscriptionstatus')} />
        <TextField source='state' label={translate('resources.transactionHistory.state')} />
        {/* <TextField source='accountCreated' label="Account created" /> */}
        <TextField source='patient.updated' label={translate('resources.transactionHistory.updated')} />
        <FunctionField label="Dashboard"  render={record =>
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={DASHBOARDLINK + record.id} target="_blank" >Dashboard</a>} />
      </Datagrid>
    </List>

  )
}


export default AchievedMedicalData;