const { token } = JSON.parse(localStorage.getItem('auth')) || {};
module.exports = Object.freeze({
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    DASHBOARDLINK: 'http://web-kuntolitedoctordashboard.azurewebsites.net/patientDashboard/',
    CONFIG: {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Ocp-Apim-Subscription-Key': '0fe30ab5e9084a06aae9acf3d2684e4f',
            'Ocp-Apim-Trace': true
        }
    }


});

