import React, { useState, useEffect } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  SelectInput,
  PasswordInput,
  useNotify,
  useRefresh,
  useQuery,
  useRedirect,
  BooleanInput,
  CheckboxGroupInput,
  required,
  minLength,
  maxLength,
  regex,
  email
} from 'react-admin';
import Constants from '../Constants';

const OrgAdminCreate = (props) => {
  const [developerGuideContent, setDeveloperGuideContent] = useState('');
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    console.log("data :", data);
    notify('simple.notify.save_orgadmin');
    redirect(`/orgadmins?filter=%7B"id"%3A"${data.id}"%7D`);
    refresh();
  };

  const createdDateTime = new Date().toLocaleString();
  const DefValues = () => ({ state: 'active', updated: createdDateTime });

  const [customers, setCustomers] = useState([]);
  const { data: customer } = useQuery({
    type: 'getList',
    resource: 'customers',
    payload: {
      sort: { field: 'customercode', order: 'ASC' },
      filter:
      {
        state: Constants.ACTIVE
      },
    },
  });

  useEffect(() => {
    if (customer) {
      setCustomers(customer.map((d) => ({ id: d.id, name: d.name })));
    }
  }, [customer]);

  const validateName = [required(), maxLength(50, 'simple.validation.maxLength50')];
  const validateEmail = [required(), email('simple.validation.validEmail'), maxLength(100, 'simple.validation.maxLength100')];
  // const validatePassword = [required(), maxLength(12, 'simple.validation.maxLength12'),
  // minLength(8, 'simple.validation.greaterorEqual8'),
  // //eslint-disable-next-line
  // regex(/^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,12}$/,
  //   'simple.validation.validPassword')];
  const validatePhoneNumber = [required(), maxLength(20, 'simple.validation.maxLength20'),
  regex(/^[0-9]+$/, 'simple.validation.onlyNumbers')];
  const validateCustomer = [required()];

  return (
    <Create {...props} title='simple.createTitles.orgadmin' onSuccess={onSuccess}>
      <SimpleForm initialValues={DefValues} >
        <TextInput source='name' validate={validateName} />
        <TextInput source='email' validate={validateEmail} />
        {/* <PasswordInput source='password' validate={validatePassword} /> */}
        <TextInput source='phonenumber' validate={validatePhoneNumber} />
        <SelectInput source='customerid' choices={customers} validate={validateCustomer} />
        <RadioButtonGroupInput source='state' choices={[{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }]} />
        <TextInput disabled source='updated' />
        <BooleanInput source="isdoctor" />
        <CheckboxGroupInput source="patientdetails" choices={[
          { id: 'pseudonym', name: 'simple.selectOptionValues.pseudonym' },
          { id: 'name', name: 'simple.selectOptionValues.name' },
          { id: 'phonenumber', name: 'simple.selectOptionValues.phonenumber' },
          { id: 'email', name: 'simple.selectOptionValues.email' },
          { id: 'doctor', name: 'simple.selectOptionValues.doctor' },
          { id: 'customercode', name: 'simple.selectOptionValues.customercode' },
          { id: 'dashboard', name: 'simple.selectOptionValues.dashboard' },
          { id: 'state', name: 'simple.selectOptionValues.state' },
          { id: 'updated', name: 'simple.selectOptionValues.updated' },
          { id: 'daysfromoperation', name: 'simple.selectOptionValues.daysfromoperation' },
          { id: 'activitylevel', name: 'simple.selectOptionValues.activitylevel' },
          { id: 'trend', name: 'simple.selectOptionValues.trend' },
        ]} />
        <BooleanInput source="removerestriction" />
      </SimpleForm>
    </Create>
  );
};

export default OrgAdminCreate;