import React, { useState, useEffect } from 'react';
import {
  Create,
  SimpleForm,
  RadioButtonGroupInput,
  SelectInput,
  useNotify,
  useRefresh,
  useRedirect,
  useQuery,
  TextInput,
  required,
  maxLength,
  regex,
  email,
  Labeled,
  Toolbar, SaveButton,BooleanInput
} from 'react-admin';
import Constants from '../Constants';
import axios from 'axios';
import { CONFIG } from './../Constants';
const PatientCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify('simple.notify.save_patient');
    redirect(`/patients?filter=%7B"id"%3A"${data.id}"%7D`);
    refresh();
  };

  const onFailure = (error) => {
    notify(`Could not create new patient: ${error.message}`);
    redirect('/patients');
    refresh();
  };
  const [treatmentTarget, setTreatmentTarget] = useState();
  const [showTreatmentTargetSide, setShowTreatmentTargetSide] = useState(treatmentTarget !== 'back');
  const [doctors, setDoctors] = useState([]);
  const [customerCode, setCustomerCode] = useState("");
  const [checkEmail, setEmail] = useState("");
  const [error, setError] = useState(null)
  const [checkPatientEmail, setPatientEmail] = useState("");
  const [treatmentTargetSide, setTreatmentTargetSide] = useState(false);
  const [selectedTreatmentTarget, setSelectedTreatmentTarget] = useState('');
  const [pseudonymValue, setPseudonymValue] = useState('');
  const [patientPseudonymInitialValue, setPatientPseudonymInitialValue] = useState('');
  const currentDate = new Date();

  // Get the components of the date and time
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it's zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const formattedDateTime = `${year}${month}${day}${hours}${minutes}${seconds}`;
  // let pseudonymValue = null

  const { data: doctor } = useQuery({
    type: 'getList',
    resource: 'doctors',
    payload: {
      sort: { field: 'name', order: 'ASC' },
      filter: JSON.parse(localStorage.getItem('auth')).role ? (JSON.parse(localStorage.getItem('auth')).role === 'orgadmin' ?
        {
          state: Constants.ACTIVE,
          customerid: JSON.parse(localStorage.getItem('auth'))?.customerid
        } : {
          state: Constants.ACTIVE,
          id: JSON.parse(localStorage.getItem('auth'))?.doctorid
        }) : { state: Constants.ACTIVE, },
    },
  });
  // useEffect(async() => {
  //   // Update the visibility of treatmentTargetSide whenever treatmentTarget changes
  //   await axios.get(process.env.REACT_APP_API_URL + `/patients/${props.id}`, CONFIG).then((res)=>{
  //     setShowTreatmentTargetSide(res.data.patient.treatmentTarget!=='back')
  //   })
  // }, [treatmentTarget]);

  const onTreatmentTargetValueChange = (value) => {
    // Update the treatmentTarget state when the value changes
    // if(value.target.value !== 'back'){
    //   setShowTreatmentTargetSide(true)
    // }
    // else setShowTreatmentTargetSide(false)

    setSelectedTreatmentTarget(value.target.value);
  };

  useEffect(() => {
    if (doctor) {
      setDoctors(doctor.map((d) => ({ id: d.id, name: d.name })));
      if (doctor.length === 1) {
        setCustomerCode(doctor[0].customercode)
      }
    }
  }, [doctor]);

  const onDoctorChange = (e) => {
    const result = doctor.filter(id => id.id === e.target.value)


    setCustomerCode(result[0].customercode)
    setEmail(result[0].email)
    if (checkPatientEmail !== "" && checkEmail !== "") {
      if (checkPatientEmail === result[0].email) {
        setError("doctor and patient cannot be same")
      }
      else {
        setError(null)
      }
    }
    if(result[0]?.customercode){
      var newPseudonymValue = getPseudonymValue(result[0]?.customercode,NewPseudonym)
      setPseudonymValue(newPseudonymValue);

    }
    else{
      getPseudonymValue(customerCode,NewPseudonym)
      setPseudonymValue(newPseudonymValue);
    }
  }
  useEffect(() => {
    setPatientPseudonymInitialValue(pseudonymValue);
  }, [pseudonymValue]);

  const onEmailChange = (e) => {
    setPatientEmail(e)
    if (checkPatientEmail !== "" && checkEmail !== "") {
      if (e === checkEmail) {
        setError("doctor and patient cannot be same")
      }
      else {
        setError(null)
      }
    }
  }

  // Create 8-character pseudonym using charset32
  const { Entropy } = require('entropy-string');
  const random = new Entropy({ bits: 40 });
  const NewPseudonym = random.string();

  const createdDateTime = new Date().toLocaleString();

  const DefValues = () => ({
    patient: {
      state: 'active', updated: createdDateTime,
    },
    doctorid: localStorage.getItem('roles') === 'doctor' ? JSON.parse(localStorage.getItem('auth')).doctorid : null,
    createdAt: new Date().toLocaleString()
  });

  const EditToolbar = props => {
    return <Toolbar {...props} >
      {(error === null) ? <SaveButton disabled={props.pristine} />
        : <SaveButton disabled={error !== null} />}
    </Toolbar>
  };

  const validateDoctor = [required()];
  const validateName = [required(), maxLength(50, 'simple.validation.maxLength50')];
  const validateEmail = [required(),onEmailChange, email('simple.validation.validEmail'), maxLength(100, 'simple.validation.maxLength100')];
  const validatePhoneNumber = [required(), maxLength(20, 'simple.validation.maxLength20'),
  regex(/^[0-9]+$/, 'simple.validation.onlyNumbers')];

  const choices = [
    { id: 'knee', name: 'Knee-KOOS' },
    { id: 'back', name: 'Back-Oswestry' },
    { id: 'shoulder', name: 'Shoulder-DASH' },
    { id: 'wrist', name: 'Wrist-DASH' },
    { id: 'oks', name: 'Knee-OKS' },
    {id:'ankle',name:'Ankle-OMA'}
];

const treatmentTargetSideChoices = [
  {id:'none',name:'None'},
  {id: 'left', name: 'Left'},
  {id: 'right', name: 'Right'},
  {id:'both',name:'Both'}
];

const month_number = [
  {id:30,name:'1 month'},
  {id:60,name:'2 months'},
  {id:90,name:'3 months'},
  {id:120,name:'4 months'},
  {id:150,name:'5 months'},
  {id:180,name:'6 months'},
]

const filteredTreatmentTargetSideChoices = selectedTreatmentTarget === 'back' ? [{ id: 'none', name: 'None' }] : treatmentTargetSideChoices;

const getPseudonymValue = (customerCode, NewPseudonym) => {
  return customerCode !== "" ? `${customerCode}-${NewPseudonym}` : NewPseudonym;
};

const convertMonthtoDays = (e) => {
    const daysPerMonth = 30; // Assuming each month has 30 days for simplicity
    const totalDays = e.target.value * daysPerMonth;
    return totalDays;
};

const defaultMonthValue = 30;

//Note :  for pseudonym creation rules if requirement is same as sprint 14 than kindly pass formattedDateTime parameter in the getPseudonymValue function
  return (
    <Create {...props} title='simple.createTitles.patient' onSuccess={onSuccess} onFailure={onFailure}>
      <SimpleForm initialValues={DefValues} toolbar={<EditToolbar />}>
        <SelectInput source='doctorid' choices={doctors} onChange={onDoctorChange}
          validate={validateDoctor} />
          <SelectInput id='treatmentTarget' source="patient.treatmentTarget" choices={choices} label="Select Treatment Target" onChange={onTreatmentTargetValueChange} />
        <SelectInput
          source="patient.position"
          choices={filteredTreatmentTargetSideChoices}
          label="Select Treatment Target Side"
        />
        <TextInput source='patient.pseudonym'  initialValue={patientPseudonymInitialValue}/>
        <TextInput source='patient.name' validate={validateName} />
        <TextInput source='patient.phonenumber' validate={validatePhoneNumber} />
        <BooleanInput source="isresearchuser" defaultValue={true}/>
        <SelectInput
          source="DaysFromLastfetch"
          choices={month_number}
          label="Months of data needed"
          onChange={convertMonthtoDays}
          defaultValue={defaultMonthValue}
        />
        <TextInput source='patient.email' validate={validateEmail} onChange={onEmailChange} />
        {error ? <Labeled label={error} className="errorMessage" /> : null}
        <RadioButtonGroupInput source='patient.state' choices={[{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }]} />
        <TextInput disabled source='patient.updated' />
        {/* <TextInput disabled source='state'/> */}
      </SimpleForm>
    </Create>

  );
};

export default PatientCreate;