import React, { useState} from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  SelectInput,
  useNotify,
  useRefresh,
  useRedirect,
  required,
  SelectArrayInput,
  maxLength,
  ImageInput,
  ImageField,
  regex,
  Labeled, useTranslate,
  CheckboxGroupInput,BooleanInput,SimpleFormIterator,ArrayInput,Button
} from 'react-admin';
const CustomerCreate = (props) => {
  const notify = useNotify();
  const defaultChatLink = '';
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();
  const onSuccess = async ({ data }) => {
    notify('simple.notify.save_customer');
    redirect(`/customers?filter=%7B"id"%3A"${data.id}"%7D`);
    refresh();
  };
  const [linkContentFields, setLinkContentFields] = useState([{ link: '', content: '' }]);
  const [chatContentFields, setChatContentFields] = useState(false);
  const createdDateTime = new Date().toLocaleString();
  const DefValues = () => ({ state: 'active', updated: createdDateTime, options: [''] });

  const validateName = [required(), maxLength(50, 'simple.validation.maxLength50')];
  const validateCustomerCode = [required(), maxLength(50, 'simple.validation.maxLength50')];
  const validateAddress = [maxLength(100, "simple.validation.maxLength100")];
  const validateCity = [maxLength(50, 'simple.validation.maxLength50')];
  const validatePostalCode = [maxLength(10, 'simple.validation.maxLength10'), regex(/^([-A-Za-z0-9]){1,20}$/, 'simple.validation.alphaOrHyphen')];
  const validateCountry = [maxLength(50, 'simple.validation.maxLength50')];
  const validateVersion = [maxLength(50, 'simple.validation.maxLength50')];
  const validateColor = [maxLength(50, 'simple.validation.maxLength50')];
  const validateProduct = [required()];
  const validateLogo = [required()];
  const validatePhoneNumber = [
    maxLength(20, 'simple.validation.maxLength20'),
    regex(/^\+?\d+$/, 'simple.validation.onlyNumbersWithOptionalPlus')
  ];
  const choices = [
    {id:1,name:'01'},
    { id: 25, name: '25' },
    { id: 50, name: '50' },
    { id: 75, name: '75' },
    { id:90,  name: '90' }
];
const handleAddField = () => {
  setLinkContentFields([...linkContentFields, { link: '', content: '' }]);
};
const chatLink = (value) => {
  if(value==true){
    setChatContentFields(true)
  }
  else setChatContentFields(false)
};

  return (
    <Create {...props} title='simple.createTitles.customer'
      onSuccess={onSuccess}>
      <SimpleForm initialValues={DefValues} >
        <TextInput source='name' validate={validateName} />
        <TextInput source='customercode' validate={validateCustomerCode} />
        <TextInput source='address' validate={validateAddress} />
        <TextInput source='city' validate={validateCity} />
        <TextInput source='postalcode' validate={validatePostalCode} />
        <TextInput source='phonenumber' validate={validatePhoneNumber}/>
        <TextInput source='smsphonenumber' validate={validatePhoneNumber}/>
        <TextInput source='country' validate={validateCountry} />
        <SelectInput source='product' validate={validateProduct}
          choices={[{ id: 'kuntoresearch', name: 'Kunto Research' },
          { id: 'kuntorecovery', name: 'Kunto Recovery' }]} />
        <TextInput source='version' validate={validateVersion} />
        <SelectArrayInput source='options'
          choices={[{ id: '', name: 'No options' }, { id: 'knee', name: 'Knee' }, { id: 'hip', name: 'Hip' }, { id: 'spine', name: 'Spine' },
          { id: 'shoulder', name: 'Shoulder' }, { id: 'wrist', name: 'Wrist' }]}
        />
        <ImageInput source="logo"  multiple={false} maxSize={1048576}
          accept="image/*" placeholder={translate('resources.customers.placeholder.logo')}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <Labeled label="resources.customers.placeholder.allowedFileTypes" />
        <TextInput source='color' validate={validateColor} />
        <BooleanInput source="contact" />
        <BooleanInput source="sharing" />
        <ArrayInput source="linkContent" label="Links and Content" defaultValue={linkContentFields}>
          <SimpleFormIterator>
            <TextInput label="Link" source="link" />
            <TextInput label="Content" multiline source="content" />
          </SimpleFormIterator>
        </ArrayInput>

        <Button  onClick={handleAddField} />
        <BooleanInput label="Chat Option" source="chatoption" onChange={chatLink}/>
        {
          chatContentFields && (
            <TextInput
            label="Chat Link"
            source="chatlink"
            defaultValue={defaultChatLink}
          />
          )
        }
        <SelectInput source="retentionperiod" choices={choices} label="Select Retention Period"/>
        <CheckboxGroupInput source='sendnotification' choices={[{ id: 'true', name: translate('resources.customers.fields.sendnotification') }]} />
        <RadioButtonGroupInput source='state' choices={[{ id: 'active', name: 'Active' }, { id: 'inactive', name: 'Inactive' }]} />
        <TextInput disabled source='updated' initialValue={new Date().toLocaleString()} />
      </SimpleForm>
    </Create>
  );
};

export default CustomerCreate;