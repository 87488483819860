import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { NewLayout } from './NewLayout';
import Organization from './organization';
import CustomerList from './components/CustomerList';
import CustomerEdit from './components/CustomerEdit';
import CustomerCreate from './components/CustomerCreate';
import PatientList from './components/PatientList';
import PatientEdit from './components/PatientEdit';
import PatientCreate from './components/PatientCreate';
import DoctorList from './components/DoctorList';
import DoctorEdit from './components/DoctorEdit';
import DoctorCreate from './components/DoctorCreate';
import OrgAdminList from './components/OrgAdminList';
import OrgAdminCreate from './components/OrgAdminCreate';
import OrgAdminEdit from './components/OrgAdminEdit';
import FAQCreate from './components/FAQCreate';
import FAQEdit from './components/FAQEdit';
import FAQList from './components/FAQList';
import { authProvider } from './authProvider';
import uploadCapableDataProvider from './DataProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import English from './i18n/admin_en';
import SettingsList from './components/SettingsList';
import Login from './login';
import TransactionHistoryEdit from './components/TransactionHistoryEdit';
import TransactionHistoryList from './components/TransactionHistoryList';
import AUDITLOGList from './components/AUDITLOGList';
import AchievedLogList from './components/AchievedLogList';
import AchievedMedicalData from './components/AchievedMedicaldataList';

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'en') {
        return English;
    }
    if (locale === 'de') {
        return import('./i18n/admin_de').then(messages => messages.default);
    }
}, 'en');

const App = () => (
    <Admin disableTelemetry dashboard={Organization} layout={NewLayout} i18nProvider={i18nProvider} dataProvider={uploadCapableDataProvider}
        authProvider={authProvider} loginPage={Login}>
        <Resource name="patients" create={PatientCreate} list={PatientList} edit={PatientEdit} />
        <Resource name="doctors" create={DoctorCreate} list={DoctorList} edit={DoctorEdit} />
        <Resource name="orgadmins" create={OrgAdminCreate} list={OrgAdminList} edit={OrgAdminEdit} />
        <Resource name="customers" create={CustomerCreate} list={CustomerList} edit={CustomerEdit} />
        <Resource name="FAQs" create={FAQCreate} list={FAQList} edit={FAQEdit} />
        <Resource name="lite-accounts" create='' list={TransactionHistoryList}  edit={TransactionHistoryEdit} />
        <Resource name="settings" list={SettingsList} create='' edit='' />
        <Resource name="AUDITLOGs" list={AUDITLOGList} create='' edit='' />
        <Resource name="AchievedLogs" list={AchievedLogList} create='' edit='' />
        <Resource name="achievedpatients" list={AchievedMedicalData} create='' edit='' />
    </Admin>
);
export default App;