import Button from '@material-ui/core/Button';
import { Typography } from '@mui/material';
import React from 'react';
import { AppBar, useLocale, useSetLocale } from 'react-admin';


export const MyAppBar = (props) => {
    const locale = useLocale();
    const setLocale = useSetLocale();

    return (
        <AppBar {...props}>
            <Typography flex="1" variant="h6" id="react-admin-title"></Typography>
            <Button className="langButton"
                disabled={locale === 'de'}
                onClick={() => setLocale('de')}
            >De</Button>
            <Button className="langButton"
                disabled={locale === 'en'}
                onClick={() => setLocale('en')}
            >En</Button>
        </AppBar>)
};
